import React from 'react';
import {FC} from 'react';
import {NavLink} from "react-router-dom";
import styles from './profile.module.sass'
import {UserType} from "../../helpers/types";
import {useCheckScreen} from "../../helpers/helpers";
import Footer from "../Common/Footer/Footer";
import {useTranslation} from 'react-i18next';
import bookMy from '../../assets/img/BookMy.png'
import bookFamily from '../../assets/img/BookFamily.png'

const Profile: FC<{ user: UserType }> = ({user}) => {
    const fullName = `${user.name} ${user.middle_name} ${user.surname}`;
    const {t} = useTranslation(['common']);

    return (
        <>
            <div className={styles.profile}>
                <div className="container">
                    <div className={styles.profileChoose}>
                        <div className={styles.profilePart}>
                            <h2 className="h1-heading">{t('links.my-profile')}</h2>
                            <div className={styles.profileInfo}>
                                <NavLink to={'me'} className={[styles.img, 'img'].join(' ')}>
                                    <img src={bookMy} alt={fullName}/>
                                    <div className={styles.title}>{fullName}</div>
                                </NavLink>
                                <p className={styles.profileText}>{t('profile.my-description')}</p>
                                <NavLink className={["button big yellow", styles.button].join(' ')} to={"me"}>{t('links.write-family')}</NavLink>
                            </div>
                        </div>
                        <div className={styles.profilePart}>
                            <h2 className="h1-heading">{t('links.family-profile')}</h2>
                            <div className={styles.profileInfo}>
                                <NavLink to={'family'} className={[styles.img, 'img'].join(' ')}>
                                    <img src={bookFamily} alt="Family member"/>
                                </NavLink>
                                <p className={styles.profileText}>{t('profile.family-description')}</p>
                                <NavLink className={["button big yellow", styles.button].join(' ')}
                                         to={"family"}>{t('links.write-family')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default Profile;