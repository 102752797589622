import {FC} from "react";
import {Form, Formik} from "formik";
import form from "../Common/Form/Form.module.sass";
import styles from "./signUp.module.sass";
import {TextInput} from "../Common/Form/Form";
import {SignUpFamilyMemberType} from "../../helpers/types";
import {useTranslation} from "react-i18next";
import SelectField from "../Common/Form/SelectField";
import {SignUpFamilyMemberValidationSchema} from "./SignUpFamilyMemberValidation";
import {signUpFamilyMember} from "../../api/apiFunctions";
import {useNavigate} from "react-router-dom";


const SignUpFamilyMemberForm: FC = () => {
    const initialValues: SignUpFamilyMemberType = {
        name: '',
        surname: '',
        middle_name: '',
        family_connection: '',
        born_day: '',
        born_month: '',
        born_year: '',
        dead_day: '',
        dead_month: '',
        dead_year: '',
    }

    type reactSelectType = {
        value: string,
        label: string
    }

    const {t} = useTranslation(['form'])

    type connectionOptionsType = {
        [key: string]: string
    }

    // @ts-ignore
    const connectionOptions: connectionOptionsType = t('family-member-connection.options');
    let connectionOptionsArr:reactSelectType[] = [];



    Object.values(connectionOptions).forEach((option) => {
        connectionOptionsArr = [...connectionOptionsArr, {
            value: option,
            label: option
        }]
    })

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SignUpFamilyMemberValidationSchema}
            onSubmit={(values, {resetForm, setSubmitting}) => {
                setSubmitting(true)
                signUpFamilyMember(values, resetForm, navigate);
                setSubmitting(false);
            }}
        >
            <Form autoComplete="off"
                  className={form.loginForm}>
                <div className={styles.layout}>
                    <div className={form.block}>
                        <TextInput name={`name`} label={t('family-member-name')} type="text"/>
                        <TextInput name={`surname`} label={t('family-member-surname')} type="text"/>
                        <TextInput name={`middle_name`} label={t('family-member-middle-name')}
                                   type="text"/>
                    </div>
                    <div>
                        <SelectField name="family_connection" options={connectionOptionsArr} label={t('family-member-connection.label')} />
                    </div>
                    <div>
                        <h4>{t('family-born-label')}</h4>
                        <div className="col-3">
                            <TextInput name={`born_day`} label={t('born-day')} type="number"/>
                            <TextInput name={`born_month`} label={t('born-month')} type="number"/>
                            <TextInput name={`born_year`} label={t('born-year')} type="number"/>
                        </div>
                    </div>
                    <div>
                        <h4>{t('family-dead-label')}</h4>
                        <div className="col-3">
                            <TextInput name={`dead_day`} label={t('born-day')} type="number"/>
                            <TextInput name={`dead_month`} label={t('born-month')} type="number"/>
                            <TextInput name={`dead_year`} label={t('born-year')} type="number"/>
                        </div>
                    </div>

                    <button type="submit"
                            className={['button yellow big', form.submit].join(' ')}
                    >{t('signup-button')}</button>
                </div>
            </Form>
        </Formik>
    )
}


export default SignUpFamilyMemberForm