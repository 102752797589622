import {FC} from "react";
import {Svg} from "../../helpers/types";

const Tip: FC<Svg> = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.9763184,19.8950195 C15.9763184,20.8793945 16.1967773,21.7390137 16.6376953,22.473877 C17.0786133,23.2087402 17.6938477,23.779541 18.4833984,24.1862793 C19.2729492,24.5930176 20.2009277,24.7963867 21.267334,24.7963867 L22.7028809,24.7963867 L22.7028809,32.3330078 C22.7028809,32.640625 22.7900391,32.8850098 22.9643555,33.0661621 C23.1386719,33.2473145 23.3796387,33.3378906 23.6872559,33.3378906 C23.994873,33.3378906 24.2409668,33.2473145 24.4255371,33.0661621 C24.6101074,32.8850098 24.7023926,32.640625 24.7023926,32.3330078 L24.7023926,17.003418 L26.7429199,17.003418 L26.7429199,32.3330078 C26.7429199,32.640625 26.8317871,32.8850098 27.0095215,33.0661621 C27.1872559,33.2473145 27.4265137,33.3378906 27.7272949,33.3378906 C28.0349121,33.3378906 28.2810059,33.2473145 28.4655762,33.0661621 C28.6501465,32.8850098 28.7424316,32.640625 28.7424316,32.3330078 L28.7424316,17.003418 L30.3112793,17.003418 C30.6188965,17.003418 30.8649902,16.9128418 31.0495605,16.7316895 C31.2341309,16.5505371 31.326416,16.3061523 31.326416,15.9985352 C31.326416,15.690918 31.2341309,15.4482422 31.0495605,15.2705078 C30.8649902,15.0927734 30.6188965,15.0039062 30.3112793,15.0039062 L21.1853027,15.0039062 C20.1394043,15.0039062 19.2268066,15.2072754 18.4475098,15.6140137 C17.6682129,16.020752 17.0615234,16.5915527 16.6274414,17.326416 C16.1933594,18.0612793 15.9763184,18.9174805 15.9763184,19.8950195 Z"
                    id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
        </svg>
    )
}

export default Tip