import {FC, useEffect} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import styles from './profile.module.sass'
import Footer from "../Common/Footer/Footer";
import book from '../../assets/img/BookMy.png'
import {useCheckScreen} from "../../helpers/helpers";
import {useTranslation, Trans} from "react-i18next";
import ProfileNav from "./ProfileNav";
import {useAppSelector} from "../../helpers/hooks";
import {useDispatch} from "react-redux";
import {getOwnBiography} from '../../api/apiFunctions';
import {BiographiesType} from '../../helpers/types';
import {setMyBiography} from '../../store/reducers/biographiesSlice';

const ProfileMe: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {words_in_biography, name, middle_name, surname, birthday} = useAppSelector(state => state.biographies.me)
    const fullName = `${name} ${middle_name} ${surname}`;
    const birthdayFormatted = birthday.split('-').reverse().join('/');
    const letters: number = words_in_biography;
    const pages: number = Math.ceil(words_in_biography / 1_500);
    const lettersFormat: string = new Intl.NumberFormat('en-En').format(letters);
    const pagesFormat: string = new Intl.NumberFormat('en-En').format(pages);
    const {t} = useTranslation(['common']);

    useEffect(() => {
        if (!words_in_biography && !localStorage.getItem('greetings')) {
            localStorage.setItem('greetings', '1')
            navigate('/profile/me/greetings')
        }
        getOwnBiography().then((data: BiographiesType) => {
            dispatch(setMyBiography(data))
        })
    }, [])

    return (
        <>
            <div className={styles.profile}>
                <div className="container">
                    <ProfileNav/>
                    <div className={styles.wrap}>
                        <div className={styles.book}>
                            <div className={styles.img}>
                                <img src={book} alt={fullName}/>
                                <div className={styles.title}>{fullName}</div>
                            </div>
                            <div className={styles.pages}>
                                {t('profile.book-overview', {pages, pagesFormat, letters, lettersFormat})}
                            </div>
                        </div>
                        {useCheckScreen() &&
                          <NavLink to={'editor'} className="button big yellow">{t('links.continue')}</NavLink>}
                        <div className={styles.info}>
                            <h1 className={`h1-heading`}>{fullName}</h1>
                            <div className={styles.date}>{birthdayFormatted}</div>
                            {!useCheckScreen() &&
                              <NavLink to={words_in_biography ? 'editor' : 'greetings'}
                                       className="button big yellow">{t('links.continue')}</NavLink>}
                        </div>
                        <div className={styles.sidebar}>
                            <div className={styles.sidebarText}>
                                <Trans>{t('profile.sidebar-text')}</Trans>
                            </div>
                            <button className={`button big gray`} disabled={true}>{t('profile.publish')}</button>
                        </div>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default ProfileMe;