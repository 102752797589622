import {Form, Formik} from 'formik';
import React, {FC, SetStateAction, useEffect, useState} from 'react';
import {
    CheckboxInput,
    CheckboxLabel,
    CheckboxPrice,
    RadioPrice,
    RadioPriceCustom,
    TextInput
} from "../Common/Form/Form";
import s from './donation.module.sass'
import {useTranslation} from "react-i18next";
import {bankSchema} from "./bankSchema";
import {getLangFromLocalStorage} from "../../locales/i18n";
import Close from "../../assets/svg/Close";


type BankFormValuesType = {
    defaultPrice: number,
    customPrice: number,
    isMonthly: boolean,
    surname: string,
    email: string,
    acceptPolicy: boolean
}

const Bank = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://widget.cloudpayments.ru/bundles/cloudpayments.js');
        document.head.appendChild(script);
    }, []);
    const [isPopup, setIsPopup] = useState<boolean>(false)
    const {t} = useTranslation(['donation'])
    const pricesArr = [500, 1000, 1500, 0];
    const initialValues: BankFormValuesType = {
        defaultPrice: pricesArr[0],
        customPrice: pricesArr[0],
        isMonthly: false,
        surname: '',
        email: '',
        acceptPolicy: false,
    };

    function submit(values: BankFormValuesType) {
        const {defaultPrice, customPrice, email, surname, isMonthly} = values

        // @ts-ignore
        var widget = new cp.CloudPayments({
            language: getLangFromLocalStorage(),
            applePaySupport: false,
            googlePaySupport: false,
            yandexPaySupport: false,
            tinkoffPaySupport: false,
            tinkoffInstallmentSupport: false,
            sbpSupport: false,
        })

        const data: any = {};
        if (isMonthly) {
            data.CloudPayments = {
                // CustomerReceipt: receipt, //чек для первого платежа
                recurrent: {
                    interval: 'Month',
                    period: 1,
                    // customerReceipt: receipt //чек для регулярных платежей
                }
            }; //создание ежемесячной подписки
        }

        widget.pay('charge', // или 'charge'
            { //options
                publicId: process.env.REACT_APP_PAYMENT_PUBLIC_ID, //id из личного кабинета
                description: 'Epilogus donation', //назначение
                amount: defaultPrice || +customPrice,
                currency: 'RUB', //валюта
                skin: "classic", //дизайн виджета (необязательно)
                autoClose: 3,
                email: email,
                payer: {
                    lastName: surname,
                },
                data: data
            }, {
                onSuccess: function (options: any) { // success
                    //действие при успешной оплате
                    console.log(options)
                    setIsPopup(true)
                },
                onFail: function () { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function () { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel

                }
            }
        )
    }

    return (
        <Formik onSubmit={(values, formikHelpers) => submit(values)}
                initialValues={initialValues}
                validationSchema={bankSchema}
        >
            {({values}) => {
                const {defaultPrice, isMonthly} = values
                return (
                    <Form>
                        <div className={s.priceBox} role="group">
                            {pricesArr.map((p, index) => <RadioPrice
                                key={index}
                                name={'defaultPrice'}
                                label={p ? `${p} ₽` : t('otherAmount')}
                                className={s.priceItem}
                                value={p}/>)}
                            {!defaultPrice &&
                              <RadioPriceCustom className={s.priceItemCustom} value={pricesArr[0]} name={'customPrice'}
                                                placeholder={t('otherAmount')}/>}
                        </div>
                        <CheckboxPrice name={"isMonthly"} label={t('monthly')} className={s.priceCheckbox}
                                       value={isMonthly}/>
                        <div className={s.info}>
                            <TextInput name={`surname`} label={t('surname')} type="text"/>
                            <TextInput name={`email`} label={t('email')} type="text"/>
                        </div>
                        <div className={s.policy}>
                            <CheckboxInput name={`acceptPolicy`} label={<CheckboxLabel/>}/>
                            <button className="button big yellow" type="submit">{t('button')}</button>
                        </div>
                        {isPopup && <SuccessPaymentPopup setIsPopup={setIsPopup}/>}
                    </Form>
                )
            }}
        </Formik>
    );
};

export default Bank;


const SuccessPaymentPopup: FC<{ setIsPopup: React.Dispatch<SetStateAction<boolean>> }> = ({setIsPopup}) => {
    const {t} = useTranslation(['donation'])
    return (
        <div className={'blur active'}>
            <div className={[s.modal, s.success].join(' ')}>
                <button className={s.close} type="button" onClick={() => setIsPopup(false)}><Close/></button>
                <h2 className="h2-heading">{t('payment.success-title')}</h2>
                <p>{t('payment.success-desc')}</p>
                <button className={["button yellow big", s.closeButton].join(' ')} type="button"
                        onClick={() => setIsPopup(false)}>Закрыть
                </button>
            </div>
        </div>
    );
}