import {FC} from 'react';
import styles from './signUp.module.sass'
import Footer from "../Common/Footer/Footer";
import {useCheckScreen} from "../../helpers/helpers";
import SignUpForm from "./SignUpForm";
import {useTranslation} from "react-i18next";

const SignUp: FC = () => {
    const {t} = useTranslation(['common'])
    return (
        <>
            <div className={styles.signUp}>
                <div className="container container_outer">
                    <div className="container_sm container_sm_s container_sm_tablet">
                        <h1 className="h1-heading">{t('links.signup')}</h1>
                        <SignUpForm/>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default SignUp;