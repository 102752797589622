import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const BTH: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle id="Oval" className={className} cx="21" cy="21" r="21"/>
            <path d="M27.5505076,13.9484772 C26.4951777,11.5553299 24.0700508,11.0436548 21.101269,11.5393401 L20.1472081,7.84035533 L17.8979695,8.4213198 L18.8360406,12.1096447 C18.2444162,12.2588832 17.636802,12.386802 17.0345178,12.5573604 L16.0964467,8.89035533 L13.8472081,9.4713198 L14.801269,13.1703046 C14.3162437,13.3088832 10.2601523,14.3482234 10.2601523,14.3482234 L10.8784264,16.7573604 C10.8784264,16.7573604 12.5307107,16.2936548 12.5147208,16.3309645 C13.4314721,16.0911168 13.863198,16.5494924 14.065736,16.9812183 L16.6880711,27.1187817 C16.7200508,27.4119289 16.6667513,27.9129442 16.0378173,28.0835025 C16.0751269,28.1048223 14.4015228,28.5045685 14.4015228,28.5045685 L14.6467005,31.3134518 C14.6467005,31.3134518 18.6654822,30.2847716 19.1931472,30.1515228 L20.157868,33.8931472 L22.4071066,33.3121827 L21.4423858,29.5439086 C22.0606599,29.4 22.6629442,29.2507614 23.2492386,29.0961929 L24.2086294,32.8431472 L26.457868,32.2621827 L25.4931472,28.5258883 C28.9576142,27.6837563 31.4040609,25.4984772 30.9030457,22.156599 C30.5832487,20.1418782 28.3819797,18.4895939 26.5538071,18.3030457 C27.6784264,17.3063452 28.248731,15.851269 27.5505076,13.9484772 L27.5505076,13.9484772 Z M26.4685279,22.7695431 C26.9162437,26.0794416 22.3164975,26.4845178 20.7974619,26.884264 L19.4756345,21.9327411 C21,21.5329949 25.7116751,19.8540609 26.4685279,22.7695431 Z M23.6969543,16.0271574 C24.1713198,18.9692893 20.2378173,19.3104061 18.9692893,19.635533 L17.7647208,15.1423858 C19.0385787,14.8279188 22.7322335,13.3035533 23.6969543,16.0271574 Z" fill="#FFFFFF"/>
        </svg>
    );
};

export default BTH;