import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const USDT: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg"
             className={className}>
            <path
                d="M0,22.8729593 C4.58108879,25.2993198 9.36134802,27.8391165 13.0534154,29.8043374 L26,22.8729593 C21.3122489,29.8184539 17.4063513,35.6006802 13.0534154,42 C8.69272845,35.6147967 3.8769151,28.5691421 0,22.8729593 Z M0.497926118,20.9571463 L13.0675697,14.2677657 L25.4735969,20.9082426 L13.0746468,27.6050176 L0.497926118,20.9571463 Z M13.0534154,12.1245759 L0,18.977977 L12.9964614,0 L26,19.0204946 L13.0534154,12.1245759 Z"/>
            <path
                d="M13.0534154,29.8043374 L26,22.8729593 C21.3122489,29.8184539 13.0534154,42 13.0534154,42 L13.0534154,29.8043374 Z M13.0675697,14.2677657 L25.4735969,20.9082426 L13.0746468,27.6050176 L13.0675697,14.2677657 Z M13.0534154,12.12508 L12.9964614,0 L26,19.0204946 L13.0534154,12.12508 Z"
            />
            <polygon
                points="0.497926118 20.9573143 13.0682437 21.9861396 25.4742709 20.9147967 13.0746468 27.6120759"/>
            <polygon id="path1606" points="13.0675697 21.9861396 25.4735969 20.9147967 13.0746468 27.6120759"/>
        </svg>
    );
};

export default USDT;