const SvgLang = () => (
    <svg viewBox="0 0 31 26" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" >
            <g id="Language" transform="translate(-10.000000, -10.000000)" >
                <g transform="translate(10.000000, 10.000000)">
                    <path
                        d="M11.8962963,3.81119649 C12.6666667,3.81119649 13.1703704,3.84193194 13.7037037,3.90340285 L13.7037037,2.16684962 C13.1555556,2.24368825 12.637037,2.27442371 11.837037,2.27442371 L7.7037037,2.27442371 L7.7037037,1.16794731 C7.7037037,0.722283205 7.73333333,0.353457739 7.80740741,0 L5.92592593,0 C6,0.353457739 6.02962963,0.722283205 6.02962963,1.18331504 L6.02962963,2.27442371 L2.08888889,2.27442371 C1.3037037,2.27442371 0.8,2.24368825 0.237037037,2.16684962 L0.237037037,3.90340285 C0.77037037,3.84193194 1.34814815,3.81119649 2.04444444,3.81119649 L2.74074074,3.81119649 C3.24444444,5.37870472 3.51111111,6.03951701 4.07407407,7.03841932 C4.57777778,7.92974753 5.11111111,8.68276619 5.82222222,9.49725576 C4.2962963,10.8035126 2.65185185,11.6026345 0,12.3556531 C0.42962963,12.9088913 0.681481481,13.3391877 0.948148148,14 C2.35555556,13.5082327 3.2,13.154775 4.1037037,12.6783754 C5.15555556,12.1097695 6.16296296,11.4182217 7.00740741,10.6805708 C8.65185185,12.1405049 10.4592593,13.1394072 12.9777778,14 C13.3037037,13.1701427 13.5111111,12.8013172 14,12.2019759 C11.6148148,11.6487377 9.83703704,10.8188804 8.20740741,9.49725576 C8.9037037,8.66739846 9.45185185,7.86827662 9.95555556,6.90010977 C10.4888889,5.87047201 10.7259259,5.24039517 11.1111111,3.81119649 L11.8962963,3.81119649 Z M9.36296296,3.81119649 C9.00740741,5.05598244 8.78518519,5.59385291 8.34074074,6.42371021 C7.94074074,7.14599341 7.51111111,7.76070252 6.99259259,8.36004391 C6.42962963,7.7453348 6.02962963,7.20746432 5.57037037,6.43907794 C5.05185185,5.532382 4.81481481,4.9791438 4.44444444,3.81119649 L9.36296296,3.81119649 Z"
                        id="Shape"></path>
                    <path
                        d="M21.149645,26 L19,26 L23.8301475,13 L26.1698525,13 L31,26 L28.850355,26 L25.0557073,15.3613281 L24.9508465,15.3613281 L21.149645,26 Z M21.5101038,20.9091797 L28.4833424,20.9091797 L28.4833424,22.5595703 L21.5101038,22.5595703 L21.5101038,20.9091797 Z"
                        id="Shape"></path>
                    <path
                        d="M24.7928932,4.29289322 C25.1834175,3.90236893 25.8165825,3.90236893 26.2071068,4.29289322 C26.5976311,4.68341751 26.5976311,5.31658249 26.2071068,5.70710678 L8.20710678,23.7071068 C7.81658249,24.0976311 7.18341751,24.0976311 6.79289322,23.7071068 C6.40236893,23.3165825 6.40236893,22.6834175 6.79289322,22.2928932 L24.7928932,4.29289322 Z"
                        id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
)


export default SvgLang