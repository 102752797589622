import * as Yup from "yup";
import {LoginValidationSchema} from "./LoginValidation";
import {Form, Formik} from "formik";
import form from "../Common/Form/Form.module.sass";
import styles from "../SignUp/signUp.module.sass";
import {TextInput} from "../Common/Form/Form";
import {NavLink} from "react-router-dom";
import {login} from "../../api/apiFunctions";
import {useTranslation} from "react-i18next";

export type LoginType = Yup.InferType<typeof LoginValidationSchema>

const LoginForm = () => {
    const initialValues: LoginType = {
        username: '',
        password: ''
    }

    const {t} = useTranslation(['form']);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={LoginValidationSchema}
            onSubmit={((values, actions) => {
                actions.setSubmitting(true);
                login(values);
                actions.setSubmitting(false);
            })}
        >
            <Form className={form.loginForm}>
                <div className={styles.layout}>
                    <div className={form.block}>
                        <TextInput name={`username`} label={t('email')} type="text"/>
                        <TextInput name={`password`} label={t('password')} type="password"/>
                    </div>
                    <NavLink to='/forgot-password' className={['link', styles.linkRight].join(' ')}>{t('forgot-password')}</NavLink>
                    <button type="submit"
                            className={['button yellow big', form.submit].join(' ')}
                    >{t('login-button')}</button>
                </div>
            </Form>
        </Formik>
    )
}

export default LoginForm