import React, {FC, FormEvent, SyntheticEvent, useEffect, useRef, useState} from "react";
import styles from './Form.module.sass'
import {useField, ErrorMessage, Field} from "formik";
import Check from "../../../assets/svg/Check";
import Eye from '../../../assets/svg/Eye'
import {Trans, useTranslation} from "react-i18next";
import {handleInputChange} from "react-select/dist/declarations/src/utils";
import {NavLink} from "react-router-dom";

function handleFocus(input: HTMLInputElement): void {
    input.classList.add(`${styles.focused}`);
}

function handleBlur(input: HTMLInputElement, setTouched: (state: boolean) => void) {
    setTouched(true)
    if (input.value === '') input.classList.remove(`${styles.focused}`);
}

interface InputInterface {
    className?: string,
    name: string,
    label: string | JSX.Element,
}

interface textInputInterface extends InputInterface {
    disabled?: boolean,
    type: 'text' | 'number' | 'email' | 'password',
    eyed?: boolean
}

export const TextInput: FC<textInputInterface> = ({
                                                      className = '',
                                                      name,
                                                      label,
                                                      disabled = false,
                                                      type = 'text',
                                                      eyed
                                                  }) => {
    const [eyeIsOn, setEyeIsOn] = useState<boolean>(false)
    const [field, meta, helpers] = useField(name);
    const {error, touched} = meta;
    const length = field?.value?.length ?? 0;
    const {t} = useTranslation(['form']);


    function handleChange() {
        setEyeIsOn(!eyeIsOn);
    }

    type = eyeIsOn ? 'text' : type;
    return (
        <div className={[styles.row, error && touched ? 'error' : '', className].join(' ')}>
            {eyed && <button tabIndex={-1} type="button" onClick={handleChange} className={styles.eye}>
              <Eye className={[eyeIsOn ? styles.active : ''].join(' ')}/>
            </button>}
            <Field className={[styles.input, length ? `${styles.focused}` : ''].join(' ')}
                   type={type}
                   name={name}
                   id={name}
                   disabled={disabled}
                   onFocus={(e: any) => handleFocus(e.target)}
                   onBlur={(e: any) => handleBlur(e.target, helpers.setTouched)}
            />
            <label className={styles.label} htmlFor={name}>{label}</label>
            {touched && error ? <div className={styles.error}>{t(`errors.${error}`)}</div> : ''}
        </div>
    );
};

export const CheckboxInput: FC<InputInterface> = ({className = '', name, label}) => {
    const [field, meta, helpers] = useField(name)
    const {error, touched} = meta;
    const {t} = useTranslation(['form']);
    return (
        <div className={[styles.rowCheckbox, className].join(' ')}>
            <Field type="checkbox" name={name}
                   className={styles.checkbox}
                   checked={field.value}
                   id={name}
                   onChange={() => {
                       helpers.setValue(!field.value)
                   }}
            />
            <label htmlFor={name} className={[styles.checkbox, field.value && styles.active].join(' ')}>
                {field.value && <Check/>}
            </label>
            <label htmlFor={name} className="form-check-label label">{label}</label>
            {touched && error ? <div className={styles.error}>{t(`errors.${error}`)}</div> : ''}
        </div>
    )
}

interface RadioPriceInterface {
    className: string,
    name: string,
    value: number,
    label?: string | number
    placeholder?: string
}

export const RadioPrice: FC<RadioPriceInterface> = ({className, name, value, label}) => {
    const [field, , helpers] = useField(name);
    const changeInput = (value: number) => {
        helpers.setValue(value);
    }
    if (!field.value && !value) return null
    return (
        <div className={className}>
            <Field
                type="radio"
                name={name}
                value={value}
                id={`${name}_${value}`}
                onChange={() => changeInput(value)}
            />
            <label htmlFor={`${name}_${value}`}
                   className={styles.radioLabel}>{label}
            </label>
        </div>
    )
}

export const RadioPriceCustom: FC<RadioPriceInterface> = ({className, name, value, placeholder}) => {
    const {t} = useTranslation('form')
    const [field, meta, helpers] = useField(name);
    const {error, touched} = meta;
    const inputWidth = field.value ? `${field.value.toString().length * 0.7}em` : '100%'
    const changeInputWidth = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        helpers.setValue(target.value);
    }

    return (
        <div className={className}>
            <label htmlFor={`${name}_${value}`}>
                <Field
                    autoFocus={true}
                    type="number"
                    name={name}
                    className={'no-select'}
                    id={`${name}_${value}`}
                    placeholder={placeholder}
                    style={{width: inputWidth}}
                    value={field.value}
                    onChange={(e: FormEvent<HTMLInputElement>) => changeInputWidth(e)}
                />{field.value && '₽'}</label>
            {/*<ErrorMessage name={name} component="div" className={styles.error}/>*/}
            {touched && error ? <div className={styles.error}>{t(`errors.${error}`)}</div> : ''}
        </div>
    )
}

type CheckboxPrice = {
    className: string,
    name: string,
    value: boolean,
    label: string
}

export const CheckboxPrice: FC<CheckboxPrice> = ({className, name, label, value}) => {
    return (
        <div className={className}>
            <Field
                type="checkbox"
                name={name}
                id={`${name}_${value}`}
            />
            <label htmlFor={`${name}_${value}`}>
                {label}
                <span></span>
            </label>
            <ErrorMessage name={name} component="div" className={styles.error}/>
        </div>
    )
}

export const CheckboxLabel = () => {
    const {t} = useTranslation(['donation'])
    return (
        <span>
                <Trans t={t} i18nKey="agreement">
                    I accept the &nbsp;
                    <NavLink to={`/terms`} className="link" target="_blank">{t('publicOffer')}</NavLink> and consent to&nbsp;
                    <NavLink to={'/privacy'} className="link" target="_blank">{t('personalData')}</NavLink>
                </Trans>
        </span>
    )
}