import {FC} from 'react';
import styles from './greetings.module.sass'
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Greetings: FC = () => {
    type BodyItem = {
        title: string | undefined,
        text: string | undefined
    }

    const { t } = useTranslation('greetings' );
    // @ts-ignore
    const body: BodyItem[] = t("body");

    return (
        <div className={styles.greetings}>
            <div className="container container_outer">
                <div className="container_md container_md_tablet">
                    <h1 className="h1-heading">{t('title')}</h1>
                    <div className={styles.wrap}>
                        {body.map((part, index) => {
                                return (
                                    <div className={styles.block} key={index}>
                                        <h2 className="h2-heading">{part?.title}</h2>
                                        <p>{part?.text}</p>
                                    </div>
                                )
                            })}
                    </div>
                    <NavLink to="./../editor"
                             className={['button yellow big', styles.button].join(' ')}>{t('button')}</NavLink>
                </div>
            </div>
        </div>
    );
};

export default Greetings;