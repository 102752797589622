import React from 'react';
import s from './text.module.sass'
import {useCheckScreen} from "../../helpers/helpers";
import Footer from "../Common/Footer/Footer";
import {Trans, useTranslation} from "react-i18next";

const Terms = () => {
    type bodyItem = {
        h2?: string,
        text: string
    }
    const {t} = useTranslation('terms');

    // @ts-ignore
    const body: bodyItem[] = t('body');

    return (
        <>
            <div className={s.page}>
                <div className="container">
                    <div className="container_outer">
                        <div className="container_md container_md_tablet container_md_s">
                            <h1 className="h1-heading">{t('h1')}</h1>
                            {body.map(({h2, text}, index) => <div key={index} className={s.block}>
                                {h2 ? <h2 className="h2-heading">{h2}</h2> : null}
                                <Trans components={
                                    {
                                        p: <p/>,
                                        ul: <ul/>,
                                        li: <li/>,
                                        table: <div className={s.table}/>,
                                        row: <div className={s.tableRow}/>,
                                        cell: <div className={s.tableDate}/>
                                    }
                                }>{text}</Trans>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default Terms;


