import React, {FC, useState} from 'react';
import s from './donation.module.sass'
import {useTranslation} from "react-i18next";
import Close from "../../assets/svg/Close";
import {useCheckScreen} from "../../helpers/helpers";

type method = {
    value: string,
    label: string,
}
const DonationNav: FC<{ method: string, setMethod: (method: string) => void }> = ({method, setMethod}) => {
    const {t} = useTranslation(['donation'])
    const [isShareOpen, setIsShareOpen] = useState<boolean>(false)

    return (
        <div className={s.nav}>
            <form className={s.methods}>
                <NavMethod value="bank" label={t(useCheckScreen() ? 'nav.bank-short':'nav.bank')} checked={method === "bank"}
                           setMethod={setMethod}/>
                <NavMethod value="qr" label={t('nav.qr')} checked={method === "qr"} setMethod={setMethod}/>
                <NavMethod value="crypto" label={t('nav.crypto')} checked={method === "crypto"}
                           setMethod={setMethod}/>
            </form>
            <button type="button" onClick={() => setIsShareOpen(true)} className={[s.shareButton, 'h2-heading'].join(' ')}>{t('nav.share')}</button>
            {isShareOpen ?
                <div className={['blur'].join(' ')}>
                    <div className={[s.modal, s.share].join(' ')}>
                        <button className={s.close} type="button" onClick={() => setIsShareOpen(false)}><Close/></button>
                        <h2 className="h1-heading">{t('modal-title')}</h2>
                        <p>{t('modal-desc')}</p>
                    </div>
                </div> : null}
        </div>
    );
};

export default DonationNav;

interface NavMethod extends method {
    checked: boolean,
    setMethod: (method: string) => void
}

const NavMethod: FC<NavMethod> = ({value, label, checked, setMethod}) => {
    const handleInputChange = (value: string) => {
        setMethod(value)
    }
    return (
        <div className={s.method}>
            <input id={value} type="radio" value={value} checked={checked} name="methods"
                   onChange={(e) => handleInputChange(e.target.value)}/>
            <label htmlFor={value} className="h2-heading">{label}</label>
        </div>
    )
}

