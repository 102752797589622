import React, {useState} from 'react';
import s from './styles.module.sass'
import {useTranslation} from "react-i18next";
import MailConfirmed from "../../assets/svg/MailConfirmed";
import {Navigate} from "react-router-dom";
import Footer from "../Common/Footer/Footer";
import {useCheckScreen} from "../../helpers/helpers";

const AccountHasBeenActivated = () => {
    const {t} = useTranslation(['mail']);
    const [isNavigate, setIsNavigate] = useState(false);
    const isFooterShown = !useCheckScreen()
    if (isNavigate) return <Navigate to={{pathname: "/login"}}/>;
    setTimeout(() => {
        setIsNavigate(true)
    },4000)
    return (
        <>
            <div className={s.wrap}>
                <div className="container container_outer">
                    <div className="container_sm container_sm_tablet">
                        <div className={s.mailWrap}>
                            <MailConfirmed className={s.mail}/>
                            <h1 className="h1-heading">{t('account-activated-title')}</h1>
                            <h2 className="h2-heading">{t('account-activated-desc')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            {isFooterShown && <Footer/>}
        </>
    );
};

export default AccountHasBeenActivated;