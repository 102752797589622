import Wysywig from "./Wysywig";
import styles from './editor.module.sass'
import {NavLink} from "react-router-dom";
import Tip from "../../assets/svg/Tip";
import {useCheckScreen} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import React, {FC, useEffect, useRef, useState} from "react";
import {getAllBiographies, getBiographyById, getOwnBiography, updateBiographyText} from "../../api/apiFunctions";
import {BiographiesType} from "../../helpers/types";
import {setBiography, setFamilyBiography, setMyBiography} from "../../store/reducers/biographiesSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../helpers/hooks";

type statusType = 'start' | 'typing' | 'pending' | 'saved';
const Editor: FC<{ isOwner: boolean }> = ({isOwner}) => {
    const {t} = useTranslation(['common']);
    const [editorStatus, setEditorStatus] = useState<statusType>('start');
    const dispatch = useDispatch();
    const hashMessageInterval = useRef<string | null>(null);
    const bios = useAppSelector(state => state.biographies);
    const bio = isOwner ? bios.me : bios.family[0];
    const {text, id} = bio;

    useEffect(() => {
        setEditorStatus('pending');
        if (isOwner) {
            getOwnBiography(true).then((data: BiographiesType) => {
                dispatch(setMyBiography(data))
            })
        } else {
            getAllBiographies().then((data: BiographiesType[]) => {
                const firstFamilyBio = data.find((bio: BiographiesType) => !bio.is_own)
                if (firstFamilyBio) {
                    getBiographyById(true, firstFamilyBio.id).then((data: BiographiesType) => {
                        dispatch(setFamilyBiography(data))
                    })
                }
            })
        }
        setEditorStatus('start');
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (editorStatus !== 'start' && text !== hashMessageInterval.current) {
                setEditorStatus('pending');
                hashMessageInterval.current = text;
                updateBiographyText(id, text || '').then(() => {
                    setEditorStatus('saved');
                })
            }
        }, 3000)
        return () => {
            clearInterval(interval)
        }
    }, [text])
    const editorChange = (newValue: string): void => {
        dispatch(setBiography({...bio, text: newValue}));
        setEditorStatus('typing');
    }
    const sidebarRef = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.editor}>
            <div className="container">
                <div className="toolbarWrap">
                    <div id="toolbar"></div>
                </div>
                <div className={styles.wrap}>
                    <Wysywig editorChange={editorChange} text={text}/>
                    {!useCheckScreen(1280) &&
                      <aside ref={sidebarRef} className={styles.aside}>
                        <div className={styles.asideSticky}>
                          <span className={styles.info}>{t('editor.sidebar-text')}</span>
                          <Notification status={editorStatus}/>
                        </div>
                        <NavLink to="/profile/me/greetings" className={styles.tip}
                                 style={{width: sidebarRef?.current?.offsetWidth || "initial"}}>
                          <div className={styles.tipLogo}><Tip/></div>
                          <span>{t('editor.tip')}</span>
                        </NavLink>
                      </aside>
                    }
                </div>
            </div>
        </div>
    );
};

export default Editor;

const Notification: FC<{ status: statusType }> = ({status}) => {
    return <div className={[styles.status, status === 'saved' && styles.saved].join(' ')}><span>Saved</span></div>
}