import { configureStore } from '@reduxjs/toolkit'
import generalSlice from "./reducers/generalSlice";
import userSlice from "./reducers/userSlice";
import biographiesSlice from "./reducers/biographiesSlice";

export const store = configureStore({
  reducer: {
    general: generalSlice,
    user: userSlice,
    biographies: biographiesSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch