import React, {FC} from 'react';
import s from './donation.module.sass'
import {useTranslation} from "react-i18next";
import sbp from './../../assets/img/crypto/ETH.png'

const Qr:FC = () => {
    const {t} = useTranslation('donation')
    return (
        <div className={s.paymentBlock}>
            {<img src={sbp} className={s.sbp} alt=""/>}
            <span>{t('qr-desc')}</span>
        </div>
    );
};

export default Qr;