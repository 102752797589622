import React, {FC} from "react";
import {useAppDispatch} from "../../../helpers/hooks";
import {setBurgerOpen} from "../../../store/reducers/generalSlice";
import styles from "./header.module.sass";

type BurgerProps = {
    isBurgerOpen: boolean
}

const Burger: FC<BurgerProps> = ({isBurgerOpen}) => {
    const dispatch = useAppDispatch()
    const handleClick = () => {
        dispatch(setBurgerOpen(!isBurgerOpen))
    }
    return (
        <button className={[styles.burger, isBurgerOpen ? styles.open : ''].join(' ')}
                onClick={handleClick}>
            <span></span>
        </button>
    )
}

export default Burger