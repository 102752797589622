import React from 'react';
import styles from './../SignUp/signUp.module.sass'
import PasswordChangend from "../../assets/svg/Password changend";
import {useTranslation} from "react-i18next";

const PasswordChanged = () => {
    const {t} = useTranslation(['common'])

    return (
        <div className={'blur active'}>
            <div className={styles.modal}>
                <PasswordChangend className={styles.svg}/>
                <h2 className="h2-heading">{t('password-changed')}</h2>
            </div>
        </div>
    );
};

export default PasswordChanged;