import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const LTC: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path className={className}
                d="M20.9999112,0 C9.4019805,0 0,9.4019805 0,20.9999112 C0,32.5978419 9.4019805,41.9998224 20.9999112,41.9998224 C32.5978419,41.9998224 41.9999117,32.5978419 41.9999117,20.9999112 L41.9999117,20.9999112 C42.0160286,15.4465639 39.8255003,10.114232 35.9101336,6.17597956 C31.9947669,2.23772714 26.675292,0.0161590753 21.1219446,0 L20.9999112,0 Z M21.3558419,21.7117726 L19.1694105,29.0846228 L30.8642763,29.0846228 C31.0207337,29.0791346 31.1729546,29.136072 31.287404,29.2428914 C31.4018533,29.3497108 31.469141,29.4976481 31.4744432,29.6541119 L31.4744432,29.8473314 L30.4574983,33.3557912 C30.3651471,33.6975174 30.0484723,33.9297456 29.6947897,33.9151108 L11.7965603,33.9151108 L14.7965476,23.6948151 L11.4406296,24.7117599 L12.2033382,22.3727868 L15.5592562,21.3558419 L19.7795774,7.01691948 C19.8751738,6.67743074 20.189762,6.44673277 20.542286,6.45759981 L25.0676906,6.45759981 C25.224148,6.4521116 25.3763689,6.509049 25.4908183,6.6158684 C25.6052677,6.7226878 25.6725553,6.87062511 25.6778575,7.02708893 L25.6778575,7.22030845 L22.1185505,19.3219522 L25.4744686,18.3050073 L24.7626072,20.745675 L21.3558419,21.7117726 Z"/>
        </svg>
    );
};

export default LTC;