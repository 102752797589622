import {FC, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import styles from './profile.module.sass'
import Footer from "../Common/Footer/Footer";
import book from '../../assets/img/BookFamily.png'
import {useCheckScreen} from "../../helpers/helpers";
import {BiographiesType} from "../../helpers/types"
import {useTranslation, Trans} from "react-i18next";
import ProfileNav from "./ProfileNav";
import {useAppSelector} from "../../helpers/hooks";
import {useDispatch} from "react-redux";
import {getAllBiographies,} from "../../api/apiFunctions";
import { setAllBiographies } from '../../store/reducers/biographiesSlice';

const ProfileFamily: FC = () => {
    const familyMember = useAppSelector(state => state.biographies.family[0]);
    const dispatch = useDispatch();

    useEffect(() => {
        getAllBiographies().then((data: BiographiesType[]) => {
            dispatch(setAllBiographies(data))
        })
    }, [])

    return (
        <>
            <div className={styles.profile}>
                <div className="container">
                    <ProfileNav/>
                    {familyMember
                        ? <ProfileFamilyFilled {...familyMember} />
                        : <ProfileFamilyEmpty/>
                    }
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default ProfileFamily;


const ProfileFamilyFilled: FC<BiographiesType> = ({name, surname, middle_name, birthday, deathday, words_in_biography}) => {
    const fullName = `${name} ${middle_name} ${surname}`;
    const birthdayFormatted = birthday.split('-').reverse().join('/');
    const deathdayFormatted: string | null = deathday ? deathday.split('-').reverse().join('/') : null;
    const letters: number = words_in_biography;
    const pages: number = Math.ceil(letters / 1_500);
    const lettersFormat: string = new Intl.NumberFormat('en-En').format(letters);
    const pagesFormat: string = new Intl.NumberFormat('en-En').format(pages);
    const {t} = useTranslation(['common']);


    return (
        <div className={[styles.wrap, styles.filied].join(' ')}>
            <div className={styles.book}>
                <div className={styles.img}>
                    <img src={book} alt={fullName}/>
                    <div className={[styles.title, styles.family].join(' ')}>{fullName}</div>
                </div>
                <div className={styles.pages}>
                    {t('profile.book-overview', {pages, pagesFormat, letters, lettersFormat})}
                </div>
            </div>
            {useCheckScreen() &&
              <NavLink to={'editor'} className="button big yellow">{t('links.continue-family')}</NavLink>}
            <div className={styles.info}>
                <h1 className={`h1-heading`}>{fullName}</h1>
                <div className={styles.date}>{birthdayFormatted}{deathdayFormatted ? ' — ' + deathdayFormatted : ''}</div>
                {!useCheckScreen() &&
                  <NavLink to={'editor'} className="button big yellow">{t('links.continue-family')}</NavLink>}
            </div>
            <div className={styles.sidebar}>
                <div className={styles.sidebarText}>
                    <Trans>{t('profile.sidebar-text')}</Trans>
                </div>
                <button className={`button big gray`} disabled={true}>{t('profile.publish')}</button>
            </div>
        </div>
    );
};

const ProfileFamilyEmpty: FC = () => {
    const {t} = useTranslation(['common']);
    console.log('empty')
    return (
        <div className={[styles.wrap, styles.empty].join(' ')}>
            <div className={styles.book}>
                <div className={styles.img}>
                    <img src={book} alt={"Empty name"}/>
                </div>
            </div>
            <div className={styles.emptyText}>
                <Trans>{t('links.empty-text')}</Trans>
                <NavLink to={'signup'} className="button big yellow">{t('links.write-family')}</NavLink>
            </div>
        </div>
    )
}