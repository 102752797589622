import axios from 'axios'
import {CreateBioType, OwnBiography, UserType} from "../helpers/types"
import {
    ChangePassword,
    LoginResponse,
    PatchMeData,
    RegisterData,
    RegisterResponse,
    ResetPassword
} from "../helpers/types";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost,http://localhost:3000,http://localhost:3000/',
        'Content-Type': 'application/json',
    },
})

const instanceEncoded = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost,http://localhost:3000,http://localhost:3000/',
        'Content-Type': 'application/x-www-form-urlencoded',
    },
})

const getHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("token")}`
})

export const authAPI = {
    login: (data: URLSearchParams) => instanceEncoded.post<LoginResponse>('auth/jwt/login', data),
    logout: () => instance.post('auth/jwt/logout', null, {headers: getHeaders()}),
    refresh: () => instance.post<LoginResponse>('auth/jwt/refresh', null, {headers: getHeaders()}),
    register: (data: RegisterData) => instance.post<RegisterResponse>('auth/register', data),
    forgotPassword: (data: string) => instance.post<RegisterResponse>('auth/forgot-password', {email:data}),
    resetPassword: (data: ResetPassword) => instance.post<RegisterResponse>('auth/reset-password', data),
    changePassword: (data: ChangePassword) => instance.post('auth/change-password', data, {headers: getHeaders()}),
    verifyToken: (email: string) => instance.post<string>('auth/request-verify-token', {email} ),
    verify: (token: string) => instance.post<UserType>('auth/verify', {token} )
}
export const usersAPI = {
    me: () => instance.get<UserType>('users/me', {headers: getHeaders()}),
    patchMe: (data: PatchMeData) => instance.patch('users/me', data, {headers: getHeaders()})
}

export const biographiesAPI = {
    me: (include_biography: boolean = false) => instance.get(`biographies/me?include_biography=${include_biography}`, {headers: getHeaders()}),
    getBioById: (include_biography: boolean = false, id: string) => instance.get(`biographies/${id}/id?include_biography=${include_biography}`, {headers: getHeaders()}),
    updateMe: (data: OwnBiography) => instance.put('biographies/me', data, {headers: getHeaders()}),
    createBio: (data: CreateBioType) => instance.post('biographies', data, {headers: getHeaders()}),
    getAllBio: () => instance.get('biographies/me/all', {headers: getHeaders()}),
    updateBioById: (id: number, data: OwnBiography) => instance.put(`biographies/${id}/id`, data, {headers: getHeaders()}),
    patchBioText: (id: string, text: string) => instance.patch(`biographies/${id}/id`, {text}, {headers: getHeaders()})
}