import React, {FC} from 'react';
import styles from './IsAuth.module.sass'
import {useAppDispatch} from "../../../helpers/hooks";
import {setMenuOpen} from "../../../store/reducers/generalSlice";
import Menu from "./Menu";
import {MenuProps} from "../../../helpers/types";

const IsAuth: FC<MenuProps> = ({isOpen, user}) => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(setMenuOpen(!isOpen))
    }
    return (
        <div onClick={handleClick} className={[styles.wrap, isOpen && styles.active].join(' ')}>
            <span className={styles.name}>{user.name}</span>
            <Menu isOpen={isOpen} user={user}/>
            <button type="button" className={[styles.burger, isOpen ? styles.burgerActive : ''].join(' ')}>
                <span></span>
            </button>
        </div>
    );
};

export default IsAuth;

