import * as Yup from 'yup';

const currentDate = new Date().getFullYear();

export const SignUpFamilyMemberValidationSchema = Yup.object({
    name: Yup.string()
        .required('name.required'),
    surname: Yup.string()
        .required('surname.required'),
    middle_name: Yup.string(),
    family_connection: Yup.string()
        .required('family_connection.required'),
    born_day: Yup.number()
        .required('day.required')
        .typeError('day.regex')
        .nullable()
        .min(1, 'day.regex')
        .max(31, 'day.max')
        .integer('day.regex'),
    born_month: Yup.number()
        .required('month.required')
        .typeError('month.regex')
        .nullable()
        .positive()
        .min(1, 'month.regex')
        .max(12, 'month.max')
        .integer('month.regex'),
    born_year: Yup.number()
        .required('year.required')
        .typeError('year.regex')
        .max(currentDate, 'year.max')
        .integer('year.regex')
        .test('length', 'year.min', val => !!(val && val > currentDate - 330))
        .nullable(),
    dead_day: Yup.number()
        .required('day.required')
        // .transform((curr, orig) => orig === 0 ? null : curr)
        .typeError('day.regex')
        .nullable()
        .min(1, 'day.regex')
        .max(31, 'day.max')
        .integer('day.regex'),
    dead_month: Yup.number()
        .required('month.required')
        .typeError('month.regex')
        .nullable()
        .positive()
        .min(1, 'month.regex')
        .max(12, 'month.max')
        .integer('month.regex'),
    dead_year: Yup.number()
        .required('year.required')
        .typeError('year.regex')
        .max(currentDate, 'year.max')
        .integer('year.regex')
        .test('length', 'year.min', function (val) {
            const {born_year} = this.parent;
            return born_year <= val;
        })
        .nullable(),
})