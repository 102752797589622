import React from 'react';

const Close = () => {
    return (
        <svg viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-6, -7)" fill="#000000">
                    <g transform="translate(6.7574, 7.1005)">
                        <path
                            d="M1.70710678,0.292893219 L5.242,3.828 L8.77817459,0.292893219 C9.16869888,-0.0976310729 9.80186386,-0.0976310729 10.1923882,0.292893219 C10.5829124,0.683417511 10.5829124,1.31658249 10.1923882,1.70710678 L6.657,5.242 L10.1923882,8.77817459 C10.5829124,9.16869888 10.5829124,9.80186386 10.1923882,10.1923882 C9.80186386,10.5829124 9.16869888,10.5829124 8.77817459,10.1923882 L5.242,6.657 L1.70710678,10.1923882 C1.31658249,10.5829124 0.683417511,10.5829124 0.292893219,10.1923882 C-0.0976310729,9.80186386 -0.0976310729,9.16869888 0.292893219,8.77817459 L3.828,5.242 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Close;