import {FC} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

const Label: FC = () => {
    const {t} = useTranslation(['form']);
    return (
        <span>
                <Trans t={t} i18nKey="agreement">
                I have read and agree to the&nbsp;
                    <NavLink to={`/terms`} className="link" target="_blank">{t('links.terms')}</NavLink> and&nbsp;
                    <NavLink to={'/privacy'} className="link" target="_blank">{t('links.privacy')}</NavLink>
                </Trans>
        </span>
    )
}

export default Label