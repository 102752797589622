import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import commonEn from './en/common.json'
import formEn from './en/form.json'
import greetingsEn from './en/greetings.json'
import mailEn from './en/mail.json'
import donationEn from './en/donation.json'

import commonRu from './ru/common.json'
import formRu from './ru/form.json'
import greetingsRu from './ru/greetings.json'
import privacyRu from './ru/privacy.json'
import termsRu from './ru/terms.json'
import mailRu from './ru/mail.json'
import donationRu from './ru/donation.json'

export function getLangFromLocalStorage():string {
    const lang = localStorage.getItem('lang')
    if (!lang) localStorage.setItem('lang', 'en');
    return lang ? lang : 'en'
}


const resources = {
    en: {
        common: commonEn,
        form: formEn,
        greetings: greetingsEn,
        mail: mailEn,
        privacy: privacyRu,
        terms: termsRu,
        donation: donationEn
    } as const,
    ru: {
        common: commonRu,
        form: formRu,
        greetings: greetingsRu,
        mail: mailRu,
        privacy: privacyRu,
        terms: termsRu,
        donation: donationRu
    } as const
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: getLangFromLocalStorage(),
        fallbackLng: 'en',
        defaultNS: 'common',
        returnObjects: true,
        returnNull: false,
    });

export default i18next;