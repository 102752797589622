import React from 'react';
import styles from "./profile.module.sass";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ProfileNav = () => {
    const {t} = useTranslation('common');
    return (
        <div className={styles.profileNav}>
            <NavLink className={({isActive}) => isActive ? styles.active : ''} to="/profile/me">{t("links.my-profile")}</NavLink>
            <NavLink className={({isActive}) => isActive ? styles.active : ''} to="/profile/family">{t("links.family-profile")}</NavLink>
        </div>
    );
};

export default ProfileNav;