import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import s from './donation.module.sass'
import {useCheckScreen} from "../../helpers/helpers";
import Footer from "../Common/Footer/Footer";
import DonationNav from "./DonationNav";
import DonationMain from "./DonationMain";

const Donation: FC = () => {
    const {t} = useTranslation(['donation'])
    const [method, setMethod] = useState<string>("bank")

    return (
        <>
            <div className={s.donation}>
                <div className="container">
                    <h1 className="h1-heading">{t('heading')}</h1>
                    <DonationNav method={method} setMethod={setMethod}/>
                    <DonationMain method={method}/>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default Donation;