import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {LanguageItem} from '../../helpers/types'


interface GeneralState   {
    isLangModalOpen: boolean,
    languages: LanguageItem[],
    isBurgerOpen: boolean,
    isMenuOpen: boolean
}

const initialState: GeneralState = {
    isLangModalOpen: false,
    languages: [
        // {
        //     language: 'pl',
        //     label: 'Polski'
        // },
        // {
        //     language: 'ar',
        //     label: 'العربية'
        // },
        // {
        //     language: 'de',
        //     label: 'Deutsch'
        // },
        {
            language: 'en',
            label: 'English',
        },
        // {
        //     language: 'es',
        //     label: 'Español'
        // },
        // {
        //     language: 'fr',
        //     label: 'Français'
        // },
        // {
        //     language: 'it',
        //     label: 'Italiano'
        // },
        // {
        //     language: 'nd',
        //     label: 'Nederlands'
        // },
        // {
        //     language: 'jp',
        //     label: '日本語'
        // },
        // {
        //     language: 'pt',
        //     label: 'Português',
        // },
        // {
        //     language: 'sv',
        //     label: 'Svenska',
        // },
        // {
        //     language: 'uk',
        //     label: 'Українська',
        // },
        // {
        //     language: 'vt',
        //     label: 'Tiếng Việt'
        // },
        // {
        //     language: 'cn',
        //     label: '中文',
        // },
        {
            language: 'ru',
            label: 'Русский'
        },
    ],
    isBurgerOpen: false,
    isMenuOpen: false,
}

export const generalSlice = createSlice({
    name: 'general',
    initialState,  
    reducers: {
        toggleLangModalOpen: (state, action:PayloadAction<boolean>) => {
            state.isLangModalOpen = action.payload
        },
        setMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload
        },
        setBurgerOpen: (state, action: PayloadAction<boolean>) => {
            state.isBurgerOpen = action.payload
        },
    }
})

export const { toggleLangModalOpen, setBurgerOpen, setMenuOpen } = generalSlice.actions

export default generalSlice.reducer