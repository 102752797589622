import React, {FC, useEffect, useState} from 'react';
import styles from './home.module.sass'
import Footer from "../Common/Footer/Footer";
import {useCheckScreen} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import book from "../../assets/img/BookMy.png";
import {useAppSelector} from "../../helpers/hooks";
import {NavLink} from "react-router-dom";
import bookFamily from "../../assets/img/BookFamily.png";


const Home: FC = () => {
    const {t} = useTranslation(['common']);
    const styleSet = {
        fontSize: [160, 80],
        top: [50, 25]
    }
    const [headingStyles, seHeadingStyles] = useState({
        fontSize: styleSet.fontSize[0],
        top: styleSet.top[0]
    });
    const {name, middle_name, surname} = useAppSelector(state => state.user.user)
    const fullName = `${name} ${middle_name} ${surname}`;
    const handleScroll = () => {
        const position = window.scrollY;
        const scrollPercentage = (position / (window.innerHeight * 1.25 / 2 + 80) * 100);
        const stylePercentage = scrollPercentage < 100 ?
            (styleSet.fontSize[0] - styleSet.fontSize[1]) / 100 * scrollPercentage :
            styleSet.fontSize[0] - styleSet.fontSize[1]
        const topPercentage = scrollPercentage < 100 ? (styleSet.top[0] - styleSet.top[1]) / 100 * scrollPercentage : styleSet.top[0] - styleSet.top[1]
        seHeadingStyles({
            fontSize: styleSet.fontSize[0] - stylePercentage,
            top: styleSet.top[0] - topPercentage
        })
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className={styles.home}>
                <div className={styles.headingWrap}>
                    <h2 className={styles.heading}
                        style={{
                            fontSize: headingStyles.fontSize,
                            top: `calc(${headingStyles.top}vh - 0.5em)`
                        }}>
                        Пиши историю</h2>
                </div>

                <div className={styles.mainText}>
                    <div className="container">
                        {t('main-text')}
                    </div>
                </div>

                <div className={["container container_outer", styles.books].join(' ')}>
                    <div className={styles.book}>
                        <NavLink to={'profile/me/editor'} className={styles.img}>
                            <img src={book} alt={fullName}/>
                            <div className={styles.title}>{fullName}</div>
                        </NavLink>
                        <NavLink to={'profile/me/editor'} className="button big yellow">{t('links.continue')}</NavLink>
                    </div>
                    <div className={styles.book}>
                        <NavLink to={'profile/family/editor'} className={styles.img}>
                            <img src={bookFamily} alt="Family member"/>
                            {/*<div className={styles.title}>{fullName}</div>*/}
                        </NavLink>
                        <NavLink to={'profile/family/editor'} className="button big yellow">{t('links.continue')}</NavLink>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default Home;