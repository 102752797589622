import * as Yup from 'yup'
import i18next from "i18next";
import {store} from "../../store/store";

export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const t = i18next.getFixedT(null, ['form'], 'errors');

const getEmail = (): string => {
    return store.getState().user.user.email;
}

export const ChangePasswordValidationSchema = Yup.object({
    currentPassword: Yup.string()
        .matches(passwordRegex, {message: t('password.regex')})
        .required(t('password.required')),
    newPassword: Yup.string()
        .matches(passwordRegex, {message: t('password.regex')})
        .required(t('password.required'))
        .notOneOf([Yup.ref('currentPassword')], t('password.notOneOf'))
        .test({
            name: 'checkEmail',
            test: (value) => value !== getEmail(),
            message: t('password.notOneOfEmail')
        }),
    confirmPassword: Yup.string()
        .matches(passwordRegex, {message: t('password.regex')})
        .required(t('password.required'))
        .oneOf([Yup.ref('newPassword')], t('password.oneOf'))
})