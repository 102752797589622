import React from 'react';

const Check = () => {
    return (
        <svg viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Checkbox_active" transform="translate(-6.000000, -6.000000)" fill="#000000">
                    <path
                        d="M11.2296954,19 C11.8016074,19 12.2360406,18.7447717 12.5329949,18.234315 L18.6370558,8.2637644 C18.7250423,8.13060179 18.8075296,7.97801963 18.8845178,7.80601793 C18.9615059,7.63401622 19,7.45369185 19,7.26504481 C19,6.90994452 18.8652707,6.61032864 18.5958122,6.36619718 C18.3263536,6.12206573 18.0046531,6 17.6307107,6 C17.1357868,6 16.7453469,6.2496799 16.4593909,6.74903969 L11.1637056,15.6209987 L8.50761421,12.1254802 C8.19966159,11.7037985 7.80922166,11.4929577 7.33629442,11.4929577 C6.96235195,11.4929577 6.64615059,11.6233461 6.38769036,11.8841229 C6.12923012,12.1448997 6,12.4639351 6,12.8412292 C6,13.1963295 6.12648054,13.529236 6.37944162,13.8399488 L9.87690355,18.2509603 C10.0748731,18.5061886 10.2838409,18.6948357 10.5038071,18.8169014 C10.7237733,18.9389671 10.965736,19 11.2296954,19 Z"
                        id="Check"></path>
                </g>
            </g>
        </svg>
    );
};

export default Check;