import * as Yup from 'yup'
import {passwordRegex} from "../ChangePassword/ChangePasswordValidation";

export const LoginValidationSchema = Yup.object({
    username: Yup.string()
        .email('email.regex')
        .required('email.required'),
    password: Yup.string()
        .matches(passwordRegex, {message: 'password.regex'})
        .required('password.required')
})