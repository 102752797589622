import React, {FC, ReactElement, useState} from 'react';
import s from './donation.module.sass'
import Close from "../../assets/svg/Close";
import {Svg} from "../../helpers/types";

import qrLTC from './../../assets/img/crypto/LTC.png'
import qrBCH from './../../assets/img/crypto/BCH.png'
import qrETC from './../../assets/img/crypto/ETC.png'
import qrUSDT from './../../assets/img/crypto/USDT.png'
import qrZRX from './../../assets/img/crypto/ZRX.png'
import qrBTC from './../../assets/img/crypto/BTC.png'
import qrETH from './../../assets/img/crypto/ETH.png'

import USDT from './../../assets/svg/USDT'
import LTC from "../../assets/svg/LTC";
import ZRX from "../../assets/svg/ZRX";
import ETH from "../../assets/svg/ETH";
import ETC from "../../assets/svg/ETC";
import BCH from "../../assets/svg/BCH";
import BTC from "../../assets/svg/BTC";


type itemType = {
    value: string,
    qr: string,
    svg: ReactElement<Svg>
}

const cryptoArr: itemType[] = [
    {value: 'LTC', svg: <LTC className={s.ltc}/>, qr: qrLTC},
    {value: 'ZRX', svg: <ZRX className={s.zrx}/>, qr: qrZRX},
    {value: 'USDT', svg: <USDT className={s.usdt}/>, qr: qrUSDT},
    {value: 'ETH', svg: <ETH className={s.eth}/>, qr: qrETH},
    {value: 'ETC', svg: <ETC className={s.etc}/>, qr: qrETC},
    {value: 'BTC', svg: <BTC className={s.btc}/>, qr: qrBTC},
    {value: 'BCH', svg: <BCH className={s.bth}/>, qr: qrBCH},
]


const Crypto: FC = () => {
    const [activeItem, setActiveItem] = useState<null | itemType>(null)
    return (
        <>
            <div className={s.cryptoList}>
                {cryptoArr.map(item =>
                    <button type="button" key={item.value} className={s.cryptoItem} onClick={() => setActiveItem(item)}>
                        <>
                            {item.svg}
                            <span className="h2-heading">{item.value}</span>
                        </>
                    </button>
                )}
            </div>
            {activeItem ?
                <div className={['blur'].join(' ')}>
                    <div className={[s.modal, s.qr].join(' ')}>
                        <button className={s.close} type="button" onClick={() => setActiveItem(null)}><Close/></button>
                        <img src={activeItem.qr} alt={activeItem.value} title={activeItem.value}/>
                        <span>{activeItem.value}</span>
                    </div>
                </div> : null}
        </>
    );
};

export default Crypto;