import React, {FC} from 'react';
import s from './donation.module.sass'
import {useTranslation} from "react-i18next";
import Bank from "./Bank";
import Qr from "./Qr";
import Crypto from "./Crypto";

const DonationMain:FC<{method: string}> = ({method}) => {
    const {t} = useTranslation(['donation'])
    return (
        <div className={s.main}>
            <div className={s.content}>
                {method === 'bank' && <Bank />}
                {method === 'qr' && <Qr />}
                {method === 'crypto' && <Crypto />}
            </div>
            <div className={s.divider}>&nbsp;</div>
            <aside className={s.sidebar}>{t('sidebar')}</aside>
        </div>
    );
};

export default DonationMain;