import * as Yup from 'yup'

const patterntwodigisaftercomma = /^\d+(\.\d{0,2})?$/;

export const bankSchema = Yup.object({
    surname: Yup.string()
        .required('surname.required'),
    email: Yup.string()
        .email('email.regex')
        .required('email.required'),
    acceptPolicy: Yup.boolean()
        .oneOf([true], 'agreement')
        .default(false),
    defaultPrice: Yup.number(),
    customPrice: Yup.number()
        .when('defaultPrice', {
            is: 0,
            then: (schema => schema
                    .required('price.required')
                    .positive('price.positive')
                    .min(10, `price.min`)
                    .max(999999, `price.max`)
                    .typeError('price.type')
                    .test(
                        "is-decimal",
                        "price.toFixed",
                        (val: any) => {
                            if (val !== undefined) {
                                return patterntwodigisaftercomma.test(val);
                            }
                            return true;
                        }
                    )
                    .test(
                        "noEOrSign",
                        "price.type",
                        (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                    )
            )
        })
        .positive('price.positive')
        .min(10, `price.min`)
        .max(999999, `price.max`)
        .typeError('price.type')
        .test(
            "is-decimal",
            "price.toFixed",
            (val: any) => {
                if (val !== undefined) {
                    return patterntwodigisaftercomma.test(val);
                }
                return true;
            }
        )
        .test(
            "noEOrSign",
            "price.type",
            (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
        )
})