import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BiographiesType, OwnBiography} from "../../helpers/types";

interface BiographiesState {
    me: BiographiesType,
    family: BiographiesType[]
}


const initialState: BiographiesState = {
    me: {
        id: '',
        name: '',
        surname: '',
        middle_name: '',
        birthday: '',
        deathday: null,
        family_connection: '',
        is_own: true,
        text: '',
        words_in_biography: 0
    },
    family: [{
        id: '',
        name: '',
        surname: '',
        middle_name: '',
        birthday: '',
        deathday: null,
        family_connection: '',
        is_own: false,
        text: '',
        words_in_biography: 0
    }]
}

export const biographiesSlice = createSlice({
    name: 'biographies',
    initialState,
    reducers: {
        setMyBiography: (state, action: PayloadAction<OwnBiography>) => {
            state.me = {...state.me, ...action.payload}
        },
        setFamilyBiography: (state, action: PayloadAction<BiographiesType>) => {
            const currentBio = state.family.find((bio) => bio.id === action.payload.id)
            if (currentBio) {
                const index = state.family.indexOf(currentBio);
                state.family[index] = action.payload;
            } else {
                state.family[0] = action.payload;
            }
        },
        setAllBiographies: (state, action: PayloadAction<BiographiesType[]>) => {
            state.family = [];
            const bios = action.payload;
            bios.forEach(bio => {
                if (bio.is_own) {
                    state.me = bio
                } else {
                    // console.log(bio)
                    state.family = [...state.family, {...bio}];
                }
            })
        },
        setBiography: (state, action: PayloadAction<BiographiesType>) => {
            const isOwn = action.payload.is_own;
            if (isOwn) {
                state.me = {...state.me, ...action.payload};
            } else {
                const currentBio = state.family.find((bio) => bio.id === action.payload.id)
                if (currentBio) {
                    const index = state.family.indexOf(currentBio);
                    state.family[index] = action.payload;
                }
            }
        },
    }
})

export const {setMyBiography, setFamilyBiography, setAllBiographies, setBiography} = biographiesSlice.actions

export default biographiesSlice.reducer