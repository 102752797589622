import React, {FC} from 'react';
import styles from './header.module.sass';
import SvgLang from "../../../assets/svg/Language";
import NotAuth from "./NotAuth";
import {NavLink} from "react-router-dom";
import IsAuth from "./IsAuth";
import {useAppDispatch, useAppSelector} from "../../../helpers/hooks";
import {toggleLangModalOpen} from "../../../store/reducers/generalSlice";
import {HeaderProps} from "../../../helpers/types";
import {useCheckScreen} from "../../../helpers/helpers";
import Footer from "../Footer/Footer";
import LangPopup from "./LangPopup";
import Burger from "./Burger";
import Menu from "./Menu";
import {useTranslation} from "react-i18next";


const Header: FC<HeaderProps> = ({isAuth, user}) => {
    const dispatch = useAppDispatch();
    const {isLangModalOpen, isBurgerOpen, languages, isMenuOpen} = useAppSelector(state => state.general);
    const {t} = useTranslation(['common'])
    const label = t('label');

    return (
        <header>
            <div className="container">
                <div className={[styles.wrap, isMenuOpen && isAuth && styles.open].join(' ')}>
                    <NavLink to='/' className={styles.logoWrap}>
                        <div className={styles.logo}>EPILOGUS</div>
                    </NavLink>
                    <div className={styles.langWrap}>
                        <button className={[styles.lang, isLangModalOpen ? styles.active : ''].join(' ')}
                                onClick={() => dispatch(toggleLangModalOpen(true))}>
                            <SvgLang/>
                            <span className={styles.name}>{label}</span>
                        </button>
                        {isLangModalOpen ? <LangPopup languages={languages} label={label}/> : ''}
                    </div>
                    <div className={styles.login}>
                        {useCheckScreen() ?
                            <div className={styles.mobileWrap}>
                                <Burger isBurgerOpen={isBurgerOpen}/>
                                <div className={styles.burgerMenu}>
                                    {isAuth
                                        ? <Menu isOpen={true} user={user}/>
                                        : <NotAuth/>
                                    }
                                    <Footer/>
                                </div>
                            </div>
                            :
                            isAuth
                                ? <IsAuth isOpen={isMenuOpen} user={user}/>
                                : <NotAuth/>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;