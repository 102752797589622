import {FC} from "react";
import styles from './NotAuth.module.sass'
import {NavLink} from "react-router-dom";
import {useAppDispatch} from "../../../helpers/hooks";
import {setBurgerOpen} from "../../../store/reducers/generalSlice";
import {useTranslation} from "react-i18next";

const NotAuth: FC = () => {
    const {t} = useTranslation(['common'])
    const dispatch = useAppDispatch();
    const handleClick = (): void => {
      dispatch(setBurgerOpen(false))
    }
    return (
        <div className={styles.wrap}>
            <NavLink to={`login`} className={'button'}  onClick={handleClick}>{t('links.login')}</NavLink>
            <NavLink to={`signup`} className={'button yellow'} onClick={handleClick}>{t('links.signup')}</NavLink>
        </div>
    );
};

export default NotAuth;