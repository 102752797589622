import React, {FC} from 'react';
type PasswordChangedType = {
    className: string
}
const PasswordChanged: FC<PasswordChangedType> = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 112 112" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-905, -428)">
                    <g transform="translate(736, 388)">
                        <g transform="translate(169, 40)">
                            <circle id="Oval" fill="#99D32A" cx="56" cy="56" r="56"></circle>
                            <path
                                d="M56.0668945,27.6787109 C58.2993164,27.6787109 60.3867188,28.1972656 62.3291016,29.234375 C64.2714844,30.2714844 65.8447266,31.8227539 67.0488281,33.8881836 C68.2529297,35.9536133 68.8549805,38.5332031 68.8549805,41.6269531 L68.8559059,47.6148817 C70.1333099,47.7940074 71.1393491,48.2677578 71.8740234,49.0361328 C72.8320312,50.0380859 73.3110352,51.5322266 73.3110352,53.5185547 L73.3110352,70.9736328 C73.3110352,72.9775391 72.8320312,74.4848633 71.8740234,75.4956055 C70.9160156,76.5063477 69.496582,77.0117188 67.6157227,77.0117188 L44.4916992,77.0117188 C42.628418,77.0117188 41.2177734,76.5063477 40.2597656,75.4956055 C39.3017578,74.4848633 38.8227539,72.9775391 38.8227539,70.9736328 L38.8227539,53.5185547 C38.8227539,51.5322266 39.3017578,50.0380859 40.2597656,49.0361328 C41.0026405,48.2591811 42.0176872,47.783468 43.3049056,47.6089935 L43.3051758,41.6269531 C43.3051758,38.5332031 43.902832,35.9536133 45.0981445,33.8881836 C46.293457,31.8227539 47.8666992,30.2714844 49.8178711,29.234375 C51.769043,28.1972656 53.8520508,27.6787109 56.0668945,27.6787109 Z M56.0668945,31.8447266 C54.5551758,31.8447266 53.1577148,32.1962891 51.8745117,32.8994141 C50.5913086,33.6025391 49.5629883,34.6484375 48.7895508,36.0371094 C48.0161133,37.4257812 47.6293945,39.1308594 47.6293945,41.1523438 L47.6287539,47.5327109 L64.5307617,47.5327109 L64.5307617,41.1523438 C64.5307617,39.265625 64.1900586,37.6545312 63.5086523,36.3190625 L63.3574219,36.0371094 C62.5751953,34.6484375 61.546875,33.6025391 60.2724609,32.8994141 C58.9980469,32.1962891 57.5961914,31.8447266 56.0668945,31.8447266 Z"
                                id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PasswordChanged;