import {store} from "../store/store";
import {emptyUser, setUser} from "../store/reducers/userSlice";
import {authAPI, biographiesAPI, usersAPI} from "./api";
import {setIsAuth} from "../store/reducers/userSlice";
import {ChangePassword, OwnBiography, PatchMeData, SignUpFamilyMemberType, SignUpType} from "../helpers/types";
import {convertSignUpFamilyMemberValues, convertSignUpValues} from "../helpers/helpers";
import axios from "axios";
import {LoginType} from "../Components/Login/LoginForm";
import {setMyBiography, setBiography, setFamilyBiography} from "../store/reducers/biographiesSlice";
import {NavigateFunction} from "react-router-dom";


enum ServerResponseCode {
    Success = 200,
    BadRequest = 400,
    Unauthorized = 401,
    NotFound = 404,
    UnprocessableEntity = 422,
    InternalServerError = 500
}

export const alertError = (error: unknown) => {
    if (axios.isAxiosError(error) && error.response) {
        console.log(error)
        const detail = error.response?.data?.detail;

        switch (error.response?.status) {
            case ServerResponseCode.BadRequest:
                alert(detail)
                break;
            case ServerResponseCode.UnprocessableEntity:
                if (Array.isArray(detail)) {
                    alert(detail[0]?.msg)
                }
                break;
            case ServerResponseCode.InternalServerError:
                alert('Server error')
                break;
            case ServerResponseCode.Unauthorized:
                finishUserSession()
                alert('Unauthorized');
                break;
            case ServerResponseCode.NotFound:
                alert('Not Found. 404 Error');
                break;
            default:
                alert(error.response?.data)
        }
    }
}

const setNewToken = (token: string) => {
    localStorage.setItem('token', token);
    let date = new Date();
    const refreshDate = date.setMinutes(date.getMinutes() + 1).toString()
    localStorage.setItem('refreshDate', refreshDate);
}
const finishUserSession = () => {
    store.dispatch(setUser(emptyUser));
    store.dispatch(setIsAuth(false));
    localStorage.removeItem('token')
    localStorage.removeItem('refreshDate')
}


let reconnectionCurrentAttempt = 0;

function meReconnect(attempt: number, reconnectionMaxAttempt = 30): void {
    if (attempt < reconnectionMaxAttempt) {
        setTimeout(() => {
            me()
            reconnectionCurrentAttempt++;
        }, 3000)
    }
}

export const me = async () => {
    try {
        const response = await usersAPI.me();
        const currentUser = store.getState()?.user?.user;
        if (JSON.stringify(response?.data) !== JSON.stringify(currentUser)) {
            store.dispatch(setUser(response.data))
            store.dispatch(setIsAuth(true))
        }

        const currentDate = Date.now();
        const tokenDate = Number(localStorage.getItem('refreshDate'));

        if (!tokenDate || tokenDate < currentDate) await refreshToken();

    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error(error.message);
            meReconnect(reconnectionCurrentAttempt)
        }
        switch (error?.response?.status) {
            case 401:
                finishUserSession()
                break;
        }
    }
}

export const login = async (values: LoginType) => {
    const params = new URLSearchParams();
    params.append('username', values.username);
    params.append('password', values.password);
    try {
        const response = await authAPI.login(params);
        setNewToken(response.data.access_token)
        await me()
    } catch (error) {
        alertError(error)
    }
}

export const logout = async () => {
    try {
        await authAPI.logout();
    } catch (error) {
        alertError(error)
    } finally {
        finishUserSession()
    }
}

export const signUp = async (values: SignUpType, resetForm: Function, navigate:NavigateFunction) => {
    try {
        const registerData = await authAPI.register(convertSignUpValues(values));
        await authAPI.verifyToken(registerData.data.email);
        resetForm()
        navigate('/mail-confirmation/', {relative: "path", replace: true})
    } catch (error) {
        alertError(error)
    }
}

export const forgotPassword = async (email: string) => {
    try {
        await authAPI.forgotPassword(email);
    } catch (error) {
        alertError(error)
    }
}

export const verifyUser = async (token: string) => {
    try {
        const response = await authAPI.verify(token);
        return response.data.is_verified
    } catch (error) {
        alertError(error)
    }
}

export const signUpFamilyMember = async (values: SignUpFamilyMemberType, resetForm:Function, navigate:NavigateFunction) => {
    try {
        const response = await biographiesAPI.createBio(convertSignUpFamilyMemberValues(values));
        resetForm();
        store.dispatch(setFamilyBiography(response.data))
        navigate('/profile/family/editor', {relative: "path", replace: true})
    } catch (error) {
        alertError(error)
    }
}


export const patchMe = async (data: PatchMeData) => {
    try {
        const response = await usersAPI.patchMe(data);
        store.dispatch(setUser(response.data))
    } catch (error) {
        alertError(error)
    }
}

const refreshToken = async () => {
    try {
        const response = await authAPI.refresh();
        setNewToken(response.data.access_token)
    } catch (error) {
        alertError(error)
    }
}

export const changePassword = async (data: ChangePassword, resetForm:Function, navigate:NavigateFunction) => {
    try {
        const res = await authAPI.changePassword(data);
        if (res) return res.status;
        // await authAPI.changePassword(data).then(() => {
        //     store.dispatch(togglePasswordResetModal(true))
        //     setTimeout(() => {
        //         resetForm()
        //         store.dispatch(togglePasswordResetModal(false))
        //         navigate('/profile/me')
        //     }, 3000)
        // });
    } catch (error) {
        alertError(error)
    }
}

export const getOwnBiography = async (include_biography: boolean = false) => {
    try {
        const response = await biographiesAPI.me(include_biography);
        return response.data;
    } catch (error) {
        alertError(error)
    }
}


export const getBiographyById = async (include_biography: boolean = false, id:string) => {
    try {
        const response = await biographiesAPI.getBioById(include_biography, id);
        return response.data;
    } catch (error) {
        alertError(error)
    }
}

export const getAllBiographies = async () => {
    try {
        const response = await biographiesAPI.getAllBio();
        return response.data;
    } catch (error) {
        alertError(error)
    }
}


export const updateOwnBiography = async (data: OwnBiography) => {
    try {
        const response = await biographiesAPI.updateMe(data);
        store.dispatch(setMyBiography(response.data))
    } catch (error) {
        alertError(error)
    }
}

export const updateFamilyBiography = async (id: number, data: OwnBiography) => {
    try {
        const response = await biographiesAPI.updateBioById(id, data);
        store.dispatch(setMyBiography(response.data))
    } catch (error) {
        alertError(error)
    }
}

export const updateBiographyText = async (id: string, text: string = '') => {
    try {
        const response = await biographiesAPI.patchBioText(id, text);
        store.dispatch(setBiography(response.data));
    } catch (error) {
        console.log(error)
        alertError(error)
    }
}