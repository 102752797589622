import * as Yup from 'yup';
import {passwordRegex} from "../ChangePassword/ChangePasswordValidation";

const currentDate = new Date().getFullYear();

export const SignUpValidationSchema = Yup.object({
    name: Yup.string()
        .required('name.required'),
    surname: Yup.string()
        .required('surname.required'),
    middle_name: Yup.string(),
    day: Yup.number()
        .required('day.required')
        // .transform((curr, orig) => orig === 0 ? null : curr)
        .typeError('day.regex')
        .nullable()
        .min(1, 'day.regex')
        .max(31, 'day.max')
        .integer('day.regex'),
    month: Yup.number()
        .required('month.required')
        .typeError('month.regex')
        .nullable()
        .positive()
        .min(1, 'month.regex')
        .max(12, 'month.max')
        .integer('month.regex'),
    year: Yup.number()
        .required('year.required')
        .typeError('year.regex')
        .max(currentDate, 'year.max')
        .integer('year.regex')
        .test('length', 'year.min', val => !!(val && val > currentDate - 130))
        .nullable(),
    email: Yup.string()
        .email('email.regex')
        .required('email.required'),
    password: Yup.string()
        .matches(passwordRegex, {message: 'password.regex'})
        .required('password.required')
        .notOneOf([Yup.ref('email')], 'password.notOneOfEmail'),
    agreeTerm: Yup.boolean()
        .oneOf([true], 'agreement')
        .default(false),
})