import {FC} from 'react'
import styles from "../SignUp/signUp.module.sass";
import Footer from "../Common/Footer/Footer";
import {useCheckScreen} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword: FC = () => {
    const {t} = useTranslation(['common'])
    return (
        <>
            <div className={styles.signUp}>
                <div className="container container_outer">
                    <div className="container_sm container_sm_s container_sm_tablet">
                        <h1 className="h1-heading">{t('links.forgot-password')}</h1>
                        <ForgotPasswordForm/>
                    </div>
                </div>
            </div>
            {!useCheckScreen() && <Footer/>}
        </>
    );
};

export default ForgotPassword;
