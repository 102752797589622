import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const ETH: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
            <polygon points="12.996188 0 12.7121129 0.957131003 12.7121129 28.7283972 12.996188 29.0095195 25.9920446 21.3896256"/>
            <polygon points="12.996188 0 0 21.3896256 12.996188 29.0095195 12.996188 15.5301163"/>
            <polygon points="12.996188 31.4501871 12.836085 31.6438491 12.836085 41.5363948 12.996188 42 26 23.8342388"/>
            <polygon points="12.996188 42 12.996188 31.4501871 0 23.8342388"/>
            <polygon points="12.996188 29.0095195 25.9920446 21.3896256 12.996188 15.5301163"/>
            <polygon points="0 21.3896256 12.996188 29.0095195 12.996188 15.5301163"/>
        </svg>
    );
};

export default ETH;