import * as Yup from "yup";
import {Form, Formik} from "formik";
import form from "../Common/Form/Form.module.sass";
import styles from "../SignUp/signUp.module.sass";
import {TextInput} from "../Common/Form/Form";
import {forgotPassword, login} from "../../api/apiFunctions";
import {useTranslation} from "react-i18next";
import {ForgotPasswordSchema} from "./ForgotPasswordValidation";

export type LoginType = Yup.InferType<typeof ForgotPasswordSchema>

const LoginForm = () => {
    const initialValues: LoginType = {
        email: '',
    }

    const {t} = useTranslation(['form']);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={((values, actions) => {
                actions.setSubmitting(true);
                forgotPassword(values.email);
                actions.setSubmitting(false);
            })}
        >
            <Form className={form.loginForm}>
                <div className={styles.layout}>
                    <div className={form.block}>
                        <TextInput name={`email`} label={t('email')} type="text"/>
                    </div>
                    <button type="submit"
                            className={['button yellow big', form.submit].join(' ')}
                    >{t('login-button')}</button>
                </div>
            </Form>
        </Formik>
    )
}

export default LoginForm