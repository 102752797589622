import React, {useEffect} from 'react';
import './App.sass';
import {Route, Routes} from "react-router-dom";
import './locales/i18n'
import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
import SignUp from "./Components/SignUp/SignUp";
import Header from "./Components/Common/Header/Header";
import ProfileMe from "./Components/Profile/ProfileMe";
import Greetings from "./Components/Greetings/greetings";
import {useAppSelector} from "./helpers/hooks";
import Editor from "./Components/Editor/Editor";
import PrivateRoute, {PrivateRouteProps} from "./Components/Common/PrivateRoute";
import {me} from './api/apiFunctions';
import PublicRoute from "./Components/Common/PublicRoute";
import ProfileFamily from "./Components/Profile/ProfileFamily";
import Profile from "./Components/Profile/Profile";
import SignUpFamilyMember from "./Components/SignUp/SignUpFamilyMember";
import MailConfirmation from "./Components/InfoPages/MailConfirmation";
import Privacy from "./Components/TextPages/Privacy";
import Terms from "./Components/TextPages/Terms";
import ForgotPassword from "./Components/Login/ForgotPassword";
import AccountHasBeenActivated from "./Components/InfoPages/AccountHasBeenActivated";
import Donation from "./Components/Donation/Donation";
import ChangePassword from "./Components/ChangePassword/ChangePassword";


function App() {
    const {isAuth,user} = useAppSelector(state => state.user);
    const token = localStorage.getItem('token');
    const defaultProtectedRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
        isAuth,
        token,
        authenticationPath: '/login',
    };

    useEffect(() => {
        token && me();
    }, []);

    return (
        <div className="app">
            <Header isAuth={isAuth} user={user} />
            <Routes>
                <Route element={<Home />} path="/"/>
                <Route path="/login" element={<PublicRoute isAuth={isAuth}  outlet={<Login/>} />} />
                <Route path="/forgot-password" element={<PublicRoute isAuth={isAuth} outlet={<ForgotPassword/>} />} />
                <Route path="/signup" element={<PublicRoute isAuth={isAuth} outlet={<SignUp/>} />} />

                <Route path='/mail-confirmation/:token' element={<PublicRoute {...defaultProtectedRouteProps} outlet={<MailConfirmation />} />}/>
                <Route path='/mail-confirmation/' element={<PublicRoute {...defaultProtectedRouteProps} outlet={<MailConfirmation />} />}/>

                <Route path='/verified' element={<PublicRoute {...defaultProtectedRouteProps} outlet={<AccountHasBeenActivated />} />}/>
                <Route path="/change-password" element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ChangePassword/>} />} />
                <Route path='/profile' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<Profile user={user} />} />}/>
                <Route path='/profile/me' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ProfileMe />} />}/>
                <Route path="/profile/me/greetings" element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<Greetings/>} />} />
                <Route path='/profile/me/editor' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<Editor isOwner={true} />} />}/>
                <Route path='/profile/family' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ProfileFamily  />} />}/>
                <Route path='/profile/family/signup' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<SignUpFamilyMember />} />}/>
                <Route path='/profile/family/editor' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<Editor isOwner={false} />} />}/>
                <Route path='/privacy' element={<Privacy />}/>
                <Route path='/terms' element={<Terms />}/>
                <Route path='/donation' element={<Donation />}/>
            </Routes>
        </div>
    );
}

export default App;
