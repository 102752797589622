import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const BTC: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
                className={className}
                d="M41.3716656,26.0829466 C38.5666473,37.3343305 27.1708241,44.1817 15.9181057,41.3759631 C4.67010954,38.5709448 -2.17726006,27.174403 0.628990155,15.9239431 C3.43277658,4.6713273 14.8285998,-2.17655559 26.0778278,0.628462733 C37.329725,3.43348105 44.176684,14.8312548 41.3713577,26.0831519 L41.371563,26.0829466 L41.3716656,26.0829466 Z"
                />
            <path
                d="M30.2611281,18.0100497 C30.6791502,15.2150918 28.5512619,13.7126967 25.6415327,12.7104498 L26.58547,8.92443478 L24.2808052,8.35016775 L23.3619164,12.0365022 C22.7560308,11.8853901 22.1338226,11.7430039 21.5154128,11.6018496 L22.4409744,7.89118528 L20.1377468,7.31691825 L19.1932962,11.1017014 C18.6919161,10.9875461 18.1994672,10.8747253 17.7216984,10.7558477 L17.7243675,10.7439394 L14.5461885,9.95029293 L13.9331169,12.4118187 C13.9331169,12.4118187 15.6429831,12.8037657 15.6069503,12.8278902 C16.5402112,13.0608205 16.7089804,13.6786144 16.6809549,14.1682915 L15.6057184,18.4814537 C15.6699821,18.4977763 15.7533401,18.5213876 15.8453214,18.5583443 C15.7684308,18.53925 15.6866126,18.5184105 15.6017147,18.4980843 L14.0945974,24.540207 C13.9805447,24.8237475 13.6910501,25.2492636 13.0385579,25.0876804 C13.0616559,25.1211468 11.3634926,24.6696584 11.3634926,24.6696584 L10.2192702,27.3077553 L13.2184142,28.0554112 C13.7763586,28.1953336 14.3231134,28.3417234 14.8615529,28.4793874 L13.9078631,32.3088266 L16.2098588,32.8830937 L17.1543094,29.0943068 C17.7831903,29.2650266 18.3934902,29.4225034 18.9909579,29.5709464 L18.0496897,33.3418708 L20.3544571,33.9161378 L21.3080443,30.0938846 C25.2379854,30.8376395 28.1929866,30.5377764 29.436787,26.9831517 C30.4390339,24.1212611 29.3868953,22.4705257 27.3193698,21.3940573 C28.8252552,21.0467664 29.9595199,20.0563251 30.2619494,18.0103576 L30.2612308,18.0098444 L30.2611281,18.0100497 Z M24.995508,25.3938056 C24.283269,28.2556963 19.4647231,26.7086451 17.9024786,26.3207018 L19.1680424,21.2473595 C20.7301842,21.637356 25.7398788,22.4091364 24.9956106,25.3938056 L24.995508,25.3938056 Z M25.7082602,17.968576 C25.0585398,20.5717693 21.0480124,19.2492305 19.7470317,18.9249349 L20.8944365,14.3237152 C22.1954172,14.6480108 26.3850823,15.2532805 25.7084655,17.968576 L25.7082602,17.968576 Z"
                fill="#FFFFFF"/>
        </svg>
    );
};

export default BTC;