import {FC} from "react";
import {SignUpValidationSchema} from "./SignUpValidation";
import {Form, Formik} from "formik";
import form from "../Common/Form/Form.module.sass";
import styles from "./signUp.module.sass";
import {CheckboxInput, TextInput} from "../Common/Form/Form";
import Label from "./Label";
import {SignUpType} from "../../helpers/types";
import {useTranslation} from "react-i18next";
import {signUp} from "../../api/apiFunctions";
import { useNavigate } from "react-router-dom";


const SignUpForm: FC = () => {
    const initialValues: SignUpType = {
        name: '',
        surname: '',
        middle_name: '',
        day: '',
        month: '',
        year: '',
        email: '',
        password: '',
        agreeTerm: false
    }
    const navigate = useNavigate();

    const {t} = useTranslation(['form'])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SignUpValidationSchema}
            onSubmit={async (values, actions) => {
                actions.setSubmitting(true)
                signUp(values, actions.resetForm, navigate);
                actions.setSubmitting(false);
            }}
        >
            <Form autoComplete="off"
                  className={form.loginForm}>
                <div className={styles.layout}>
                    <div className={form.block}>
                        <TextInput name={`name`} label={t('name')} type="text"/>
                        <TextInput name={`surname`} label={t('surname')} type="text"/>
                        <TextInput name={`middle_name`} label={t('middle-name')} type="text"/>
                    </div>
                    <div>
                        <h4>{t('born-label')}</h4>
                        <div className="col-3">
                            <TextInput name={`day`} label={t('born-day')} type="number"/>
                            <TextInput name={`month`} label={t('born-month')} type="number"/>
                            <TextInput name={`year`} label={t('born-year')} type="number"/>
                        </div>
                    </div>
                    <div className={form.block}>
                        <TextInput name={`email`} label={t('email')} type="email"/>
                        <TextInput name={`password`} label={t('password')} type="password"/>
                    </div>
                    <div>
                        <CheckboxInput name={`agreeTerm`} label={<Label/>}/>
                    </div>
                    <button type="submit"
                            className={['button yellow big', form.submit].join(' ')}
                    >{t('signup-button')}</button>
                </div>
            </Form>
        </Formik>
    )
}


export default SignUpForm