import {FC, useEffect, useRef, useState} from 'react';
import styles from './footer.module.sass'
import {NavLink} from "react-router-dom";
import {useAppDispatch} from "../../../helpers/hooks";
import {setBurgerOpen} from "../../../store/reducers/generalSlice";
import {useTranslation} from "react-i18next";
import Heart from "../../../assets/svg/Heart";
import {useCheckScreen} from "../../../helpers/helpers";

const Footer: FC = () => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setBurgerOpen(false))
    }

    const [right, setRight] = useState(0)

    const ref = useRef<HTMLDivElement>(null);
    const donation = useRef<HTMLAnchorElement>(null);

    const donationWidth: number = (!useCheckScreen(820) && donation.current?.offsetWidth) || 0;

    useEffect(() => {
        const refWidth: number = ref.current?.offsetWidth || 0;
        setRight((window.innerWidth - refWidth) / 2)
    }, [ref, window.innerWidth])

    const {t} = useTranslation();
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div ref={ref} className={styles.wrap}>
                    <span className={styles.year}>2024</span>
                    <nav className={styles.list} style={{paddingRight: donationWidth}}>
                        <NavLink className="link" to="/privacy" onClick={handleClick}>{t('links.privacy')}</NavLink>
                        <NavLink className="link" to="/terms" onClick={handleClick}>{t('links.terms')}</NavLink>
                        <NavLink className="link" to="/about" onClick={handleClick}>{t('links.about')}</NavLink>
                        <NavLink className="link" to="/contacts" onClick={handleClick}>{t('links.contacts')}</NavLink>
                    </nav>
                    <NavLink to="/donation" ref={donation} className={[styles.donation, 'link'].join(' ')}
                             onClick={handleClick} style={{right: right}}>
                        <Heart className={styles.svg}/>
                        <span>{t('links.donating')}</span>
                    </NavLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;