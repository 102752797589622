import {LanguagePopup} from "../../../helpers/types";
import {FC, useRef} from "react";
import {useAppDispatch} from "../../../helpers/hooks";
import styles from "./header.module.sass";
import {toggleLangModalOpen} from "../../../store/reducers/generalSlice";
import SvgLang from "../../../assets/svg/Language";
import {useTranslation} from "react-i18next";


const LangPopup: FC<LanguagePopup> = ({languages, label}) => {
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation()
    const rootEl = useRef<HTMLDivElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (e.target === rootEl.current) {
            dispatch(toggleLangModalOpen(false))
        }
    }

    const changeLang = (lang: string):void => {
        localStorage.setItem('lang', lang)
        i18n.changeLanguage(lang)
        dispatch(toggleLangModalOpen(false))
    }

    return (
        <div className={'blur active'} ref={rootEl} onClick={handleClick}>
            <div className={styles.popupWrap}>
                <button className={[styles.popupButton].join(' ')}
                        onClick={() => dispatch(toggleLangModalOpen(false))}>
                    <SvgLang/>
                    <span className={styles.name}>{label}</span>
                </button>
                <div className={styles.popup}>
                    {languages.map((lang) => <button
                        key={lang.language}
                        onClick={() => changeLang(lang.language)}
                    >{lang.label}</button>)}
                </div>
            </div>
        </div>
    )
}

export default LangPopup