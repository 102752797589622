import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const Heart: FC<Svg> = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-14, -12)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="donate" transform="translate(14, 12)">
                        <path
                            d="M12.0126582,22 C12.1392405,22 12.2974684,21.9582146 12.4873418,21.8746439 C12.6772152,21.7910731 12.8481013,21.6991453 13,21.5988604 C15.2447257,20.1781576 17.1898734,18.6843305 18.835443,17.1173789 C20.4810127,15.5504274 21.7531646,13.9416904 22.6518987,12.2911681 C23.5506329,10.6406458 24,8.97132004 24,7.28319088 C24,6.21348528 23.8291139,5.23361823 23.4873418,4.34358974 C23.1455696,3.45356125 22.664557,2.68262108 22.0443038,2.03076923 C21.4240506,1.37891738 20.7046414,0.877492877 19.8860759,0.526495726 C19.0675105,0.175498575 18.1814346,0 17.2278481,0 C16.0464135,0 15.0147679,0.296676163 14.1329114,0.89002849 C13.2510549,1.48338082 12.5443038,2.27312441 12.0126582,3.25925926 C11.4725738,2.28148148 10.7594937,1.49382716 9.87341772,0.896296296 C8.98734177,0.298765432 7.9535865,0 6.7721519,0 C5.82700422,0 4.94303797,0.175498575 4.12025316,0.526495726 C3.29746835,0.877492877 2.57805907,1.37891738 1.96202532,2.03076923 C1.34599156,2.68262108 0.864978903,3.45356125 0.518987342,4.34358974 C0.172995781,5.23361823 0,6.21348528 0,7.28319088 C0,8.97132004 0.449367089,10.6406458 1.34810127,12.2911681 C2.24683544,13.9416904 3.52109705,15.5504274 5.17088608,17.1173789 C6.82067511,18.6843305 8.76371308,20.1781576 11,21.5988604 C11.1603376,21.6991453 11.3333333,21.7910731 11.5189873,21.8746439 C11.7046414,21.9582146 11.8691983,22 12.0126582,22 Z"
                            id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Heart;