import * as Yup from "yup";
import {Form, Formik} from "formik";
import form from "../Common/Form/Form.module.sass";
import styles from "../SignUp/signUp.module.sass";
import {TextInput} from "../Common/Form/Form";
import {useTranslation} from "react-i18next";
import {ChangePasswordValidationSchema} from "./ChangePasswordValidation";
import {ChangePassword} from "../../helpers/types";
import {changePassword} from "../../api/apiFunctions";
import {useNavigate} from "react-router-dom";
import PasswordChanged from "./PasswordChanged";
import {useState} from "react";


type Login = Yup.InferType<typeof ChangePasswordValidationSchema>

const LoginForm = () => {
    const initialValues: Login = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
    const {t} = useTranslation(['form'])
    const navigate = useNavigate()
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState<boolean>(false)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ChangePasswordValidationSchema}
            onSubmit={((values, {resetForm, setSubmitting}) => {
                setSubmitting(true);
                const data: ChangePassword = {
                    old_password: values.currentPassword,
                    new_password: values.newPassword
                }
                changePassword(data, resetForm, navigate).then(status => {
                    if (status === 200) {
                        setIsPasswordResetModalOpen(true);
                        setTimeout(() => {
                            resetForm()
                            setIsPasswordResetModalOpen(false);
                            navigate('/profile/me')
                        }, 3000)
                    }
                })
                setSubmitting(false);
            })}
        >
            <Form autoComplete="off" className={form.loginForm}>
                <div className={styles.layout}>
                    <div className={form.block}>
                        <TextInput name={`currentPassword`} label={t('current-password')} type="password" eyed={true}/>
                        <TextInput name={`newPassword`} label={t('new-password')} type="password" eyed={true}/>
                        <TextInput name={`confirmPassword`} label={t('confirm-password')} type="password" eyed={true}/>
                    </div>
                    <button type="submit"
                            className={['button yellow big', form.submit].join(' ')}
                    >{t('change-password-button')}</button>
                </div>
                {isPasswordResetModalOpen && <PasswordChanged/>}
            </Form>
        </Formik>
    )
}

export default LoginForm