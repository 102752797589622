import React, {FC} from 'react';
import styles from './editor.module.sass'
import {Editor} from '@tinymce/tinymce-react';

type WysywigType = {
    text: string | null,
    editorChange: (val: string) => void
}

const Wysywig: FC<WysywigType> = ({text, editorChange}) => {
    return (
        <div className={styles.editorWrap} id="my-editor">
            <Editor
                apiKey={process.env.REACT_APP_WYSYWIG_KEY}
                value={text || ''}
                onEditorChange={(newValue, editor) => editorChange(newValue)}
                init={{
                    menubar: false,
                    toolbar: 'undo redo | formatting list alignment | H1 H2 H3',
                    plugins: 'lists',
                    inline: true,
                    toolbar_persist: true,
                    fixed_toolbar_container: '#toolbar',
                    toolbar_groups: {
                        formatting: {
                            icon: 'bold',
                            tooltip: 'Formatting',
                            items: 'bold italic underline',
                        },
                        list: {
                            icon: 'ordered-list',
                            tooltip: 'List',
                            items: 'bullist numlist'
                        },
                        alignment: {
                            icon: 'align-left',
                            tooltip: 'Alignment',
                            items: 'alignleft aligncenter alignright',
                        }
                    },
                    content_style: 'body { font: 400 16px/2 Inter,sans-serif; } h1,h2,h3,ul,ol,p {margin: 0;} h1 {font: 700 36px/44px Inter,sans-serif;} h2 {font: 500 24px/32px Inter,sans-serif;}',
                    mobile: {
                        toolbar_mode: 'floating',
                    }
                }}
            />
        </div>
    );
}

export default Wysywig
