import React, {FC} from 'react';
import Select, {OnChangeValue, StylesConfig} from "react-select";
import { useField} from "formik";
import styles from "./Form.module.sass";
import svg from '../../../assets/img/Path.svg'
import {useTranslation} from "react-i18next";


type optionType = {
    value: string,
    label: string
}

type SelectFieldType = {
    options: optionType[],
    name: string,
    label: string
}

const SelectField: FC<SelectFieldType> = ({options, name, label}) => {
    const [field, meta, {setValue}] = useField(name);
    const {error, touched} = meta;
    const {t} = useTranslation(['form']);

    const onChange = (value: OnChangeValue<optionType, false>) => {
        if (value) setValue(value.value);
    };


    const customStyles: StylesConfig<optionType, false> = {
        control: (styles, state) => ({
            position: 'relative',
            background: 'transparent',
            borderBottom: state.isFocused ? '2px solid #FFCE90' : '2px solid #585E6C',
            padding: '16px 0 10px',
            font: '400 16px/20px Inter, sans-serif',
            transition: 'all .2s ease',
            display: 'grid',
            gridTemplateColumns: '1fr 20px',
            gap: '10px',
            margin: 0,
            ':after': {
                content: '""',
                width: '20px',
                height: '20px',
                backgroundImage: `url(${svg})`,
            },
            ':hover': {
                borderBottom: '2px solid #FFCE90'
            }
        }),
        indicatorsContainer: (styles) => ({
            display: 'none'
        }),
        valueContainer: (styles) => ({
            padding: 0,
        }),
        singleValue: (styles) => ({
            margin: 0,
            padding: 0,
        }),
        input: () => ({
            opacity: 0,
            position: 'absolute'
        }),
        dropdownIndicator: () => ({

        }),
        menu: () => ({
            borderRadius: '9px',
            boxShadow: '0px 59px 65px -8px rgba(48,36,20, 0.52182), 0px 30px 36px -7px rgba(29,24,14, 0.413297), 0px 10px 8px -7px rgba(0,0,0, 0)',
            backgroundColor: 'rgba(255,255,255, 1)',
            height: "auto",
        }),
        menuList: () => ({
            padding: '10px 0 20px',
        }),
        option: () => ({
            font: '400, 16px/20px Inter-Regular',
            padding: '10px 24px',
            cursor: 'pointer',
            transition: 'all .2s ease',
            ':hover': {
                backgroundColor: 'rgba(255,206,144, 1)',

            }
        })
    }
    return (
        <div
            className={[styles.row, styles.select, field.value && styles.active, error && touched ? 'error' : ''].join(' ')}>
            <label className={[styles.label].join(' ')} htmlFor={name}>{label}</label>
            {/*<ErrorMessage name={name} component="div" className={styles.error}/>*/}
            {touched && error ? <div className={styles.error}>{t(`errors.${error}`)}</div> : ''}
            <Select options={options}
                    onChange={onChange}
                    placeholder=""
                    styles={customStyles}
                    isSearchable={true}
            />
        </div>
    )
};

export default SelectField;
