import {FC, useEffect, useState} from "react";
import {NavigateFunction} from "react-router-dom";
import {SignUpFamilyMemberType, SignUpType} from "./types";


export const useCheckScreen = (checkWidth: number = 820): boolean => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width < checkWidth);
}

export const useCheckBetweenScreen = (min: number = 820, max: number): boolean => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (min <= width && width < max);
}

function twoDigits(num: number | '') {
    return ('0' + num).slice(-2);
}

export const convertSignUpValues = (values: SignUpType) => {
    return {
        ...values,
        is_active: true,
        is_superuser: false,
        is_verified: false,
        birthday: `${values.year}-${twoDigits(values.month)}-${twoDigits(values.day)}`,
    }
}

export const convertSignUpFamilyMemberValues = (values: SignUpFamilyMemberType) => {
    return {
        ...values,
        is_own: false,
        birthday: `${values.born_year}-${twoDigits(values.born_month)}-${twoDigits(values.born_day)}`,
        deathday: `${values.dead_year}-${twoDigits(values.dead_month)}-${twoDigits(values.dead_day)}`,
        text: ''
    }
}