import React, {useState} from 'react';
import Mail from "../../assets/svg/Mail";
import s from './styles.module.sass'
import {useTranslation} from "react-i18next";
import {Navigate, useParams} from "react-router-dom";
import {verifyUser} from "../../api/apiFunctions";
import {useCheckScreen} from "../../helpers/helpers";
import Footer from "../Common/Footer/Footer";

const MailConfirmation = () => {
    const {t} = useTranslation(['mail']);
    let {token} = useParams();
    const [isNavigate, setIsNavigate] = useState(false);
    const isFooterShown = !useCheckScreen()
    if (isNavigate) return <Navigate to={{pathname: "/verified"}}/>;
    if (token) {
        verifyUser(token).then(isVerified => {
            if (isVerified) {
                setIsNavigate(true)
            }
        })
    }
    return (
        <>
            {!token ?
                <div className={s.wrap}>
                    <div className="container container_outer">
                        <div className="container_sm container_sm_tablet">
                            <div className={s.mailWrap}>
                                <Mail className={s.mail}/>
                                <h1 className="h1-heading">{t('confirmation-title')}</h1>
                                <h2 className="h2-heading">{t('confirmation-desc')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {isFooterShown && <Footer/>}
        </>
    );
};

export default MailConfirmation;