import {FC} from "react";
import {useAppDispatch} from "../../../helpers/hooks";
import {setBurgerOpen, setMenuOpen} from "../../../store/reducers/generalSlice";
import styles from "./IsAuth.module.sass";
import {useCheckBetweenScreen, useCheckScreen} from "../../../helpers/helpers";
import {NavLink} from "react-router-dom";
import {logout} from "../../../api/apiFunctions";
import { useTranslation } from 'react-i18next';
import {MenuProps} from "../../../helpers/types";


const Menu: FC<MenuProps> = ({isOpen = false, user}) => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setMenuOpen(false))
        dispatch(setBurgerOpen(false))
    }
    const {t} = useTranslation(['common']);

    return (
        <div className={[styles.menuWrap, isOpen ? styles.active : ''].join(' ')}>
            {useCheckBetweenScreen(820, 1280)
                && <div className={styles.menuBlur}></div>}
            <div className={styles.menu}>
                {useCheckBetweenScreen(820, 1280)
                    && <button onClick={handleClick}
                               className={[styles.burger, isOpen ? styles.burgerActive : ''].join(' ')}>
                    <span></span>
                  </button>}
                {useCheckScreen(1280) && <span className={styles.menuName}>{user.name}</span>}
                <NavLink to="/profile/me" className="link" onClick={handleClick}>{t('links.my-profile')}</NavLink>
                <NavLink to="/profile/family" className="link" onClick={handleClick}>{t('links.family-profile')}</NavLink>
                <NavLink to="/change-password" className="link" onClick={handleClick}>{t('links.change-password')}</NavLink>
                {/*<NavLink to="/change-email" className="link" onClick={handleClick}>{t('links.change-email')}</NavLink>*/}
                <button className="link" onClick={logout}>{t('links.exit')}</button>
            </div>
        </div>
    )
}

export default Menu