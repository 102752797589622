import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const MailConfirmed: FC<Svg> = ({className}) => {
    return (
        <svg className={className} viewBox="0 0 110 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-905, -298)">
                    <g id="Group-3" transform="translate(616, 299)">
                        <g id="Mail" transform="translate(290.619, 0)">
                            <g id="Group-2" fillRule="nonzero" stroke="#000000" strokeWidth="2">
                                <path
                                    d="M13.8927528,76 L94.1962173,76 C102.116202,76 106.761905,71.4154252 106.761905,62.3343017 L106.761905,13.6658986 C106.761905,4.58468483 102.071802,0 92.8687442,0 L12.5654157,0 C4.64565722,0 0,4.58468483 0,13.6658986 L0,62.3343017 C0,71.4154252 4.68992142,76 13.8927528,76 Z"
                                    id="Path"></path>
                                <path
                                    d="M2.05623942,5.31442652 L44.9864851,43.8560832 C47.5870741,46.22222 50.4153145,47.3144265 53.33512,47.3144265 C56.2549255,47.3144265 59.083633,46.22222 61.6843622,43.8560832 L104.614379,5.31442652"
                                    id="Path"></path>
                                <path d="M4.21018788,73.7633399 L38.9303653,39.120639 L4.21018788,73.7633399 Z"
                                      id="Path"></path>
                                <path d="M102.60024,73.8122353 L67.7823655,39.120639 L102.60024,73.8122353 Z"
                                      id="Path"></path>
                            </g>
                            <g id="Group" transform="translate(26.381, 11)">
                                <circle id="Oval" fill="#FFCE90" cx="27" cy="27" r="27"></circle>
                                <polyline id="Path-2" stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round" points="16 26.2774415 24.8245711 35 40 20"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default MailConfirmed;