import React, {FC} from 'react';
import {Svg} from "../../helpers/types";

const USDT: FC<Svg> = ({width, height, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path className={className}
                d="M7.68981662,0.206279772 L0.0314960739,18.6978462 C-0.028320802,18.8390207 -0.00123862213,19.0085148 0.0983161271,19.1160401 L20.7815974,41.8990723 C20.9036683,42.0336426 21.0963317,42.0336426 21.2184026,41.8990723 L41.9016839,19.1174625 C42.0012386,19.0099372 42.0283208,18.8404431 41.9685039,18.6992686 L34.3101834,0.2077022 C34.2597083,0.0808658976 34.1493892,0 34.0280543,0 L7.97442055,0 C7.85247982,-0.001716221 7.74102625,0.0790540272 7.68981662,0.206279772 L7.68981662,0.206279772 Z"/>
            <path
                d="M24.0369537,20.5967878 L24.0369537,20.5967878 C23.867252,20.6095897 22.9904598,20.6622195 21.0346474,20.6622195 C19.4790483,20.6622195 18.3745729,20.6152794 17.9870873,20.5967878 L17.9870873,20.5967878 C11.9754038,20.3307937 7.48820744,19.2781969 7.48820744,18.0179255 C7.48820744,16.7576541 11.9754038,15.7064797 17.9870873,15.4362184 L17.9870873,19.5484582 C18.3802297,19.5769068 19.5059178,19.6437609 21.0615169,19.6437609 C22.9282358,19.6437609 23.8630095,19.5655274 24.031297,19.5498807 L24.031297,15.4390632 C30.0302529,15.7079021 34.50755,16.760499 34.50755,18.0179255 C34.50755,19.275352 30.0316671,20.3279489 24.031297,20.5953654 L24.031297,20.5953654 L24.0369537,20.5967878 Z M24.0369537,15.0137572 L24.0369537,11.3339355 L32.4089054,11.3339355 L32.4089054,5.72245628 L9.61513569,5.72245628 L9.61513569,11.3339355 L17.9856732,11.3339355 L17.9856732,15.0123348 C11.1820483,15.3266914 6.06554133,16.6822655 6.06554133,18.3066784 C6.06554133,19.9310914 11.1820483,21.2852431 17.9856732,21.6010221 L17.9856732,33.3929518 L24.0355396,33.3929518 L24.0355396,21.5967548 C30.8236085,21.2823982 35.9316303,19.9282466 35.9316303,18.305256 C35.9316303,16.6822655 30.827851,15.3281138 24.0355396,15.0123348 L24.0355396,15.0123348 L24.0369537,15.0137572 Z"
                fill="#FFFFFF"/>
        </svg>
    );
};

export default USDT;